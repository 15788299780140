import { Flex } from "@chakra-ui/react";
import truncate from "lodash/truncate";
import { Suspense } from "react";

import { useDatasetShowSuspenseQuery } from "../../../../../hooks/api";
import { IconWithText } from "../../../../ui/IconWithText";
import { ResourceIcon } from "../../../../ui/ResourceIcon";
import { Skeleton } from "../../../../ui/Skeleton";

const DatasetColumnLabel = ({
  columnName,
  children,
}: {
  columnName: string;
  children?: React.ReactNode;
}) => {
  return (
    <Flex gap={2} alignItems="center">
      <span style={{ fontFamily: "monospace" }}>{columnName}</span>
      {children}
    </Flex>
  );
};

const mergeDatasetColumnPattern = /dataset\/([^/]+)\/(.+)/;

function DatasetNameLookup({ datasetId }: { datasetId: string }) {
  const { data: dataset } = useDatasetShowSuspenseQuery(datasetId);

  return (
    <IconWithText as="span" fontSize="fdy_sm" color="fdy_gray.700">
      via <ResourceIcon.datasets size={14} /> {truncate(dataset.name)}
    </IconWithText>
  );
}

function MergeDatasetColumn({ columnName }: { columnName: string }) {
  const [_, inputDatasetId, inputColName] =
    columnName.match(mergeDatasetColumnPattern) ?? [];

  return (
    <DatasetColumnLabel columnName={inputColName}>
      <Suspense fallback={<Skeleton width="100px" height="16px" />}>
        <DatasetNameLookup datasetId={inputDatasetId} />
      </Suspense>
    </DatasetColumnLabel>
  );
}

/*
 * Renders a dataset column name.
 * If the column name starts with "dataset/", it is treated as a merge dataset column, which will fetch the dataset name
 * and display it alongside the column name.
 * Otherwise, it simply displays the column name.
 */
export function DatasetColumn({ columnName }: { columnName: string }) {
  const isMergeDataset = columnName.startsWith("dataset/");

  if (isMergeDataset) {
    return <MergeDatasetColumn columnName={columnName} />;
  }
  return <DatasetColumnLabel columnName={columnName} />;
}
