import { ResourceType } from "@fdy/faraday-js";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useAccountConfig } from "../../../hooks/accountConfigHooks";
import { AnimatedZapLogo } from "../AnimatedZapLogo";
import { RequestShortcutTabs } from "./RequestShortcutTabs";
import { useDynamicGetResourceQuery } from "./useDynamicGetResourceQuery";

/**
 * Details of a resource but an optional ID (in case it doesn't exist yet).
 */
export type MinimalResource = {
  id: string;
  resource_type: ResourceType;
};

export function ApiShortcuts({ resource }: { resource: MinimalResource }) {
  const resourceQuery = useDynamicGetResourceQuery(resource);
  const config = useAccountConfig();

  const apiSpecQuery = useSuspenseQuery({
    queryKey: ["api-spec"],
    queryFn: () =>
      fetch("/api.json").then((res) =>
        res.ok ? res.json() : Promise.reject("API spec failed to load")
      ),
  });

  if (resourceQuery.isLoading || apiSpecQuery.isLoading || config.loading) {
    return <AnimatedZapLogo />;
  }

  const resourceData = resourceQuery.data;
  if (!resourceData) {
    throw new Error(
      `No resource data for ${resource.resource_type} ${resource.id} `
    );
  }

  const spec = apiSpecQuery.data;
  if (!spec) {
    throw new Error("No api spec found");
  }

  return (
    <RequestShortcutTabs
      spec={spec}
      resourceData={resourceData}
      apiKey={config.api_key}
    />
  );
}
