import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Upload } from "@fdy/faraday-js";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";

import { useFdyClient } from "../../../../services/FdyClientProvider";
import { bytesToFileSize } from "../../../../utils/formatFileSize";
import { date } from "../../../../utils/formatters";
import { CardV2 } from "../../../ui/Card/CardV2";
import { Pagination } from "../../../ui/Pagination-v2/Pagination";
import { TableBlankslate } from "../../../ui/TableV2/parts";
import { DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv } from "../../__generated__/DatasetShowPageQuery";
import { CsvActionsMenu } from "./DatasetsDataCsvFilesActionMenu";

const PAGE_SIZE = 10;

function TableFileRows({ uploads }: { uploads: Upload[] }) {
  if (!uploads || !uploads.length) {
    return (
      <TableBlankslate
        title="No files found"
        text="Get started by uploading a file above"
      />
    );
  }

  return (
    <>
      {uploads.map((file, idx) => (
        <Tr key={idx}>
          <Td>{date(file.created_at, "MMM d, yyyy")}</Td>
          <Td>{file.subpath}</Td>
          <Td>{bytesToFileSize(file.file_size)}</Td>
          <Td textAlign="right">
            <CsvActionsMenu filePath={file.subpath} />
          </Td>
        </Tr>
      ))}
    </>
  );
}

export const uploadQueryKeys = {
  uploadedFiles: (uploadDirectory: string) => [
    "uploadedFiles",
    uploadDirectory,
  ],
};

/**
 * Renders a table of uploaded CSV files.
 * Only usable for hosted/uploaded csv datasets.
 */
export function DatasetsDataCsvFilesTable({
  options,
}: {
  options: DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv;
}) {
  const [page, setPage] = useState<number>(1);
  const client = useFdyClient();
  const { data: uploads, isPending } = useSuspenseQuery({
    queryKey: uploadQueryKeys.uploadedFiles(options.uploadDirectory),
    queryFn: () => client.uploads.getUploads(),
    select: (data) =>
      data
        .filter((file) =>
          // Only show files in the upload directory. This could become an API param if needed.
          file.subpath.startsWith(options.uploadDirectory + "/")
        )
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ),
  });

  return (
    <CardV2
      title="Files in dataset"
      text="Manage your files currently used in this dataset"
    >
      <Table mb={4}>
        <Thead>
          <Tr>
            <Th>Upload date</Th>
            <Th>File name</Th>
            <Th>File size</Th>
            <Th>
              <VisuallyHidden>Actions</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <TableFileRows
            uploads={uploads.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)}
          />
        </Tbody>
      </Table>
      <Pagination
        disabled={isPending}
        total={uploads.length}
        pageSize={PAGE_SIZE}
        current={page}
        onChange={setPage}
      />
    </CardV2>
  );
}
