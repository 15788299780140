import { number } from "../../utils/formatters";
import { CardV2 } from "../ui/Card/CardV2";
import { PendingValue } from "../ui/PendingValue";
import { SummaryGroup, SummaryItem } from "../ui/SummaryCard";
import { CohortFragment } from "./__generated__/CohortFragment";

export function CohortSummaryCard({ cohort }: { cohort: CohortFragment }) {
  return (
    <CardV2
      title="Summary"
      text="The total population found in this cohort based on
          configuration."
    >
      <SummaryGroup>
        <SummaryItem title="Individuals">
          <PendingValue value={cohort.populationCount} formatter={number} />
        </SummaryItem>
        <SummaryItem title="Residences">
          <PendingValue value={cohort.residenceCount} formatter={number} />
        </SummaryItem>
      </SummaryGroup>
    </CardV2>
  );
}
