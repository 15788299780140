import { gql } from "@apollo/client";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_CREATED_CONNECTION,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { PageContainer } from "../ui/PageContainer";
import { PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import {
  ConnectionCreateMutation,
  ConnectionCreateMutationVariables,
} from "./__generated__/ConnectionCreateMutation";
import { connectionFragment } from "./connectionFragment";
import { ConnectionForm, ConnectionFormState } from "./ConnectionsForm";
import { connectionsQuery } from "./connectionsQuery";

export const connectionCreateMutation = gql`
  mutation ConnectionCreateMutation($input: ConnectionPostInput!) {
    createConnection(connectionPostInput: $input) {
      ...ConnectionDetails
    }
  }
  ${connectionFragment}
`;

/**
 * Renders a form to create a new connection
 */
export function ConnectionsNewPage() {
  const router = useRouter();
  const track = useHubSpotEvent();

  function onSave(input: ConnectionFormState) {
    createConnection({
      variables: {
        input,
      },
    });
  }

  const [createConnection, { loading }] = useSojournerMutation<
    ConnectionCreateMutation,
    ConnectionCreateMutationVariables
  >(connectionCreateMutation, {
    refetchQueries: [{ query: connectionsQuery }],
    onCompleted(data) {
      if (data.createConnection?.id) {
        router.navigate(ROUTE_NAMES.CONNECTIONS_SHOW, {
          id: data.createConnection.id,
        });
        track(HUBSPOT_USER_CREATED_CONNECTION, {
          resource_id: data.createConnection.id,
          connection_type: data.createConnection.options.type,
        });
      }
    },
  });

  return (
    <PageLayout analyticsStackName="connection-new">
      <PageHeader
        title="New Connection"
        //   rightContent={headerCta}
        crumbs={[
          {
            label: "Connections",
            routeName: ROUTE_NAMES.CONNECTIONS,
          },
          {
            label: "New connection",
            routeName: ROUTE_NAMES.CONNECTIONS_NEW,
          },
        ]}
        backBtnProps={{
          label: "Connections",
          routeName: ROUTE_NAMES.CONNECTIONS,
        }}
      />
      <PageContainer>
        <UsageWarningNotice resource="connections" />
        <ConnectionForm loading={loading} onSave={onSave} />
      </PageContainer>
    </PageLayout>
  );
}
