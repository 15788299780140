import { format } from "date-fns";
import { capitalize } from "lodash";
import numeral from "numeral";

import { TraitsMap } from "../../../hooks/useTraitsQuery";
import { largeNumber } from "../../../utils/formatters";
import {
  AnalysisDimensionRow,
  Dimension,
  DimensionTrait,
  NumberBin,
  TraitBin,
} from "./types";

const HOUSING_DENSITY_BREAKS = [
  [0, "Unpopulated"],
  [72, "Sparsely populated"],
  [337, "Lightly rural"],
  [640, "Moderately rural"],
  [1200, "Low density suburban"],
  [2400, "Medium density suburban"],
  [3456, "Low density urban"],
  [4672, "Medium density urban"],
  [6400, "High density urban"],
  [10000, "Moderate urban core"],
  [Infinity, "Dense urban core"],
].reverse() as [number, string][];

/**
 * Convert a number representing housing density to a human readable label.
 * We don't have these available in the api anywhere, so they're hard coded here.
 */
function formatHousingDensity(v: number) {
  let d = HOUSING_DENSITY_BREAKS[0][1];

  for (let i = 0; i < HOUSING_DENSITY_BREAKS.length; i += 1) {
    const [max, description] = HOUSING_DENSITY_BREAKS[i];
    if (max >= v) d = description;
    else break;
  }

  return d;
}

/**
 * Format a number for a bin to appropriate comma separation or another human friendly format.
 */
function formatBinValue(
  num: number,
  field: DimensionTrait,
  header?: boolean
): string {
  // special treatment for housing density fields
  if (field?.name.includes("density")) {
    if (header) {
      return numeral(num).format("0a") + "/mi²";
    }

    return formatHousingDensity(num);
  }

  if (field?.name.includes("year")) {
    return num.toString();
  }

  return largeNumber(num);
}

/**
 * Format a date string into our preferred format.
 */
function formatYearDate(str: string) {
  return format(str, "MMM d, yyyy");
}

/**
 * Sort an array of personas by ID.
 *
 * Notes:
 * Maybe we should sort by BQ cluster number like old personas but it's not on the API.
 * We could also sort by name if they're all starting with 'Cluster X' and fallback to ID.
 * Just sort by ID for now.
 */
export function sortPersonas<T extends { id: string }>(personas: T[]) {
  // clone array, because most likely the original is readonly if it came from api
  return [...personas].sort((a, b) => a.id.localeCompare(b.id));
}

/**
 * Type guard that all bins are NumberBins.
 */
function areNumberBins(bins: TraitBin[]): bins is NumberBin[] {
  return bins.every((b) => b.__typename === "AnalysisDimensionsTraitBinNumber");
}

/**
 * Condense large amount of bins into a smaller amount of bins.
 */
function capOrdinalBins(bins: TraitBin[]): TraitBin[] {
  if (!areNumberBins(bins)) return bins;

  let cappedBins: NumberBin[] = [...bins];

  while (cappedBins.length > 15) {
    cappedBins = cappedBins.reduce((acc, b, i) => {
      if (i % 2 === 0) {
        if (i === cappedBins.length - 1) {
          acc.push(b);
        } else {
          const nextBin = cappedBins[i + 1];

          acc.push({
            ...b,
            count: b.count + nextBin.count,
            max: nextBin.max,
            percent: b.percent + nextBin.percent,
          });
        }
      }
      return acc;
    }, [] as NumberBin[]);
  }

  return cappedBins;
}

/**
 * Given a bin from persona dimensions and a field, return a human readable label for the bin.
 *
 * Examples:
 * - number; {min: 0, max: 100, count: 100} => "0 - 100"
 * - text: {value: "foo", count: 100} => "Foo"
 * - date: {min: "2020-01-01", max: "2020-01-02", count: 100} => "Jan 1, 2020 - Jan 2, 2020"
 * - boolean: {value: true, count: 100} => "True"
 */
function getTraitBinLabel(
  bin: TraitBin,
  field: DimensionTrait,
  header?: boolean
) {
  if (bin.__typename === "AnalysisDimensionsTraitBinText") {
    return capitalize(bin.category.toLowerCase());
  }

  if (bin.__typename === "AnalysisDimensionsTraitBinNumber") {
    const min = formatBinValue(bin.min, field, header);

    if (bin.max) {
      const max = formatBinValue(bin.max, field, header);
      return `${min}-${max}`;
    }

    return `${min}+`;
  }

  if (bin.__typename === "AnalysisDimensionsTraitBinBoolean") {
    if (bin.value === null) {
      return "Unknown/null";
    }

    return capitalize(String(bin.value));
  }

  if (bin.__typename === "AnalysisDimensionsTraitBinDate") {
    const min = formatYearDate(bin.minDate);

    if (bin.maxDate) {
      return `${min}-${formatYearDate(bin.maxDate)}`;
    }

    return `${min}+`;
  }

  throw new RangeError(`Unknown bin type ${JSON.stringify(bin)}`);
}

export function itemsAreNonNull<T>(array: T[]): array is NonNullable<T>[] {
  return array.some((item) => item === null || item === undefined) === false;
}

function findBinWithHighestCount(bins: TraitBin[]): TraitBin {
  return bins.reduce((curr, bin) => {
    if (bin.count > curr.count) {
      return bin;
    }

    return curr;
  }, bins[0]);
}

/**
 * Given an array personas and their "dimensions" (various counts of various persona traits),
 * return a list of those trait names and grouping of those persona counts by that trait.
 */
export function makeTraitDimensionRows(
  dimensions: Dimension[],
  traits: TraitsMap
): AnalysisDimensionRow[] {
  if (!dimensions.length) return [];

  // Get all fields that are used in the dimensions.
  // Should be fine to get them from first dimension set, since they should all be the same.
  const fieldNames = dimensions[0].traits.map((t) => t.traitName);

  const rows: AnalysisDimensionRow[] = [];

  // for each field name, return a row with the field literate, headers for each
  // chart, and an array of charts for each persona
  for (const name of fieldNames) {
    // get the field/trait info from the trait map or make a fallback using just the name.
    const trait = traits[name];

    if (!trait) {
      console.warn(`Could not find trait in traits map: ${name}. Skipping...`);
      continue;
    }

    const personas: AnalysisDimensionRow["personas"] = [];

    for (const dim of dimensions) {
      const dimTrait = dim.traits.find((t) => t.traitName === name);

      if (!dimTrait) {
        console.warn(
          `Could not find trait ${name} in dimensions ${dim.personaId}`
        );
        continue;
      }

      const allBins = dimTrait.bins;

      if (!itemsAreNonNull(allBins)) {
        console.warn(
          `No bins for trait ${name} in dimensions ${dim.personaId}`
        );
        continue;
      }

      const bins = capOrdinalBins(allBins);
      const maxBin = findBinWithHighestCount(bins);

      if (!maxBin) {
        console.warn(`Could not find max bin for ${name}`);
        continue;
      }

      const common = getTraitBinLabel(maxBin, trait);

      if (!common) {
        console.warn(`Could not get label for bin ${JSON.stringify(maxBin)}`);
        continue;
      }

      personas.push({
        id: dim.personaId,
        common,
        bins: bins.map((bin) => ({
          percent: bin.percent,
          count: bin.count,
          label: getTraitBinLabel(bin, trait),
          header: getTraitBinLabel(bin, trait, true),
        })),
      });
    }

    const firstPersonaDims = personas[0];

    if (!firstPersonaDims) {
      console.warn(`No personas dimensions made for field ${name}`);
      continue;
    }

    rows.push({
      personas: sortPersonas(personas),
      trait,
    });
  }

  return rows;
}
