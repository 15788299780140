import { Box, Button, useClipboard } from "@chakra-ui/react";
import { Check, Copy } from "@phosphor-icons/react";

export function CodeSample({ code }: { code: string }) {
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <Box
      sx={{
        bg: "fdy_gray.900",
        color: "white",
        fontFamily: "monospace",
        borderRadius: 6,
        position: "relative",
      }}
    >
      <Button
        aria-label="Copy to clipboard"
        variant="icon"
        onClick={onCopy}
        size="sm"
        leftIcon={hasCopied ? <Check /> : <Copy />}
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
          color: "currentcolor",
          fontSize: 12,
          _hover: {
            bg: "fdy_gray.800",
          },
        }}
      >
        {hasCopied ? "Copied" : "Copy"}
      </Button>
      <Box
        sx={{
          overflow: "auto",
          p: 4,
          maxHeight: "20em",
        }}
      >
        <pre>
          <code>{code}</code>
        </pre>
      </Box>
    </Box>
  );
}
