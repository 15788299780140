import { StylesConfig } from "react-select";

import { colors } from "../../../styles/chakra-theme-v2";

export type DatasetSelectOption = {
  value: string;
  label: string;
  isDisabled?: boolean;
  hint?: string;
  fillRate?: number | null;
  isRecommended?: boolean;
};

export const reactSelectStyle: StylesConfig<DatasetSelectOption> = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
    backgroundColor: "#ffffff",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  control: (provided, state) => {
    const borderColor =
      state.isFocused || state.menuIsOpen
        ? colors.fdy_purple[500]
        : colors.fdy_gray[400];
    return {
      display: "flex",
      height: "3rem",
      padding: ".25rem",
      borderWidth: "1px",
      borderColor,
      borderRadius: "6px",
      backgroundColor: state.isDisabled ? colors.fdy_gray[100] : "inherit",
      "&:hover": {
        borderColor: state.isDisabled
          ? colors.fdy_gray[400]
          : colors.fdy_gray[600],
      },
      opacity: state.isDisabled ? 0.6 : 1,
      pointerEvents: state.isDisabled ? "none" : "auto",
    };
  },
  option: (provided, state) => {
    const backgroundColor = () => {
      if (state.data.isRecommended) {
        if (state.isFocused) return colors.fdy_green[380];
        return colors.fdy_green[300];
      }

      if (state.isFocused) return colors.fdy_gray[200];

      return colors.white;
    };

    const color = () => {
      if (state.isSelected) return colors.fdy_purple[500];
      if (state.isDisabled) return colors.fdy_gray[600];
      return colors.black;
    };

    return {
      ...provided,
      backgroundColor: backgroundColor(),
      color: color(),
    };
  },
  menu: (provided) => ({
    minWidth: "min-content",
    maxWidth: 700,
    ...provided,
  }),
};
