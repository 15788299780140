import { Box, Select } from "@chakra-ui/react";
import { ResourceStatus, Stream } from "@fdy/faraday-js";
import { useMemo, useState } from "react";

import { Blankslate } from "../../ui/Blankslate";
import { CardV2 } from "../../ui/Card/CardV2";
import { EventStreamAnalysisChart } from "./EventStreamAnalysisChart";
import { prepareTimeSeriesData } from "./eventStreamAnalysisUtils";
import { useStreamAnalysisQuery } from "./useStreamAnalysisQuery";

export enum StreamAnalysisTimeFrame {
  // values are used for the select labels
  All = "All time",
  LastThreeYears = "Last 3 years",
}

function EventStreamAnalysisDropdown({
  onChange,
  timeFrame,
}: {
  onChange: (timeFrame: StreamAnalysisTimeFrame) => void;
  timeFrame: StreamAnalysisTimeFrame;
}) {
  return (
    <Box mb={4} maxW={200}>
      <Select
        value={timeFrame}
        onChange={(e) => onChange(e.target.value as StreamAnalysisTimeFrame)}
      >
        {Object.entries(StreamAnalysisTimeFrame).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </Box>
  );
}

function EventStreamAnalysis({ stream }: { stream: Stream }) {
  const analysis = useStreamAnalysisQuery(stream.id);

  const allTime = analysis?.time_series ?? [];

  const [timeFrame, setTimeFrame] = useState<StreamAnalysisTimeFrame>(
    StreamAnalysisTimeFrame.LastThreeYears
  );

  const data = useMemo(
    () => prepareTimeSeriesData(allTime, timeFrame),
    [timeFrame, allTime]
  );

  if (
    !stream.last_updated_output_at &&
    stream.status !== ResourceStatus.Error
  ) {
    return (
      <Blankslate
        title="Event stream is building"
        text="Check back later to view analysis"
        filled
      />
    );
  }

  if (!data?.length) {
    return (
      <Blankslate
        title="Events analysis not yet built"
        text="Events must build or rebuild to view analysis. Event must also be date-based."
        filled
      />
    );
  }

  return (
    <>
      <EventStreamAnalysisDropdown
        onChange={setTimeFrame}
        timeFrame={timeFrame}
      />
      <EventStreamAnalysisChart data={data} />
    </>
  );
}

export function EventStreamAnalysisCard({ stream }: { stream: Stream }) {
  if (!stream.properties?.datetime) {
    return (
      <CardV2 title="Analysis">
        <Blankslate
          title="Event stream is not date-based"
          text="Analysis is only available for date-based event streams."
          filled
        />
      </CardV2>
    );
  }

  return (
    <CardV2 title="Analysis">
      {/* nested so card suspense boundary is used by inner queries */}
      <EventStreamAnalysis stream={stream} />
    </CardV2>
  );
}
