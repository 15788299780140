import { Skeleton } from "@chakra-ui/react";
import { ResourceType, Trait, TraitCategory } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  useDatasetListQuery,
  useTraitListSuspenseQuery,
} from "../../hooks/api";
import { percent } from "../../utils/formatters";
import { ProviderPill, traitProvider } from "../ui/ProviderPill";
import { ResourceLink } from "../ui/ResourceLink";
import { ResourceTable } from "../ui/ResourceTable/ResourceTable";
import { useUrlParamPagination } from "../ui/ResourceTable/useUrlParamPaginationState";
import { TraitActionsMenu } from "./TraitActionsMenu";
import { TraitDatasetLinks } from "./TraitDatasetLinks";
import { TraitFilters, useTraitFilters } from "./TraitFilters";
import {
  traitCategoryToLiterate,
  traitLiterate,
  traitTierToLiterate,
} from "./traitUtils";

const BLANK_SLATE_PROPS = {
  title: "No traits found",
  text: "Traits are emitted by Datasets.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Use Datasets to add Traits.",
  },
};

/**
 * Render list of account's traits with various meta data,
 */
export function TraitsTable() {
  const { data: traits } = useTraitListSuspenseQuery();
  const filterProps = useTraitFilters(traits);

  const pagination = useUrlParamPagination();

  const { data: datasets = [], isLoading: isLoadingDatasets } =
    useDatasetListQuery();

  const tableHeaders: ColumnDef<Trait>[] = useMemo(
    () => [
      {
        accessorFn: (trait) => traitLiterate(trait),
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }}>
            {traitLiterate(row.original)}
          </ResourceLink>
        ),
        sortingFn: "alphanumeric",
      },
      {
        accessorFn: (trait) => traitProvider(trait),
        size: 100,
        header: "Provider",
        cell: ({ row }) => <ProviderPill provider={row.original.name} />,
      },
      {
        accessorFn: (trait) => traitCategoryToLiterate(trait.category),
        header: "Category",
        size: 100,
      },
      {
        accessorFn: (trait) => traitTierToLiterate(trait.tier),
        header: "Tier",
        size: 80,
      },
      {
        accessorFn: (trait) => trait.coverage,
        header: "Coverage",
        size: 80,
        cell: ({ row }) => {
          const coverage = row.original.coverage;
          return coverage !== undefined ? percent(coverage) : null;
        },
      },
      {
        accessorFn: (trait) => trait.description,
        header: "Details",
        cell: ({ row }) => {
          const trait = row.original;

          if (trait.category !== TraitCategory.UserDefined) {
            return trait.description;
          }

          if (isLoadingDatasets) {
            return <Skeleton height={6} width={12} borderRadius="full" />;
          }

          return <TraitDatasetLinks trait={trait} datasets={datasets} />;
        },
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <TraitActionsMenu
              trait={row.original}
              buttonProps={{ my: -2, variant: "icon" }}
            />
          );
        },
      },
    ],
    [datasets, isLoadingDatasets]
  );

  return (
    <>
      <TraitFilters {...filterProps} />

      <ResourceTable<Trait>
        pagination={pagination}
        resourceType={ResourceType.Traits}
        blankslate={BLANK_SLATE_PROPS}
        data={filterProps.filteredTraits}
        columns={tableHeaders}
        canSelectRow={(row) =>
          row.original.category === TraitCategory.UserDefined
        }
        defaultSort={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />
    </>
  );
}
