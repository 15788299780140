import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import {
  createContext,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRoute } from "react-router5";

import {
  StateToConfirm,
  useConfirmOnStateChange,
} from "../../hooks/useConfirmOnStateChange";
import { AnalyticsStack } from "./Analytics/AnalyticsStack";
import { AnimatedZapLogo } from "./AnimatedZapLogo";
import { Button } from "./Button";
import { ErrorBoundary } from "./ErrorBoundary";
import { InlineButtons } from "./InlineButtons";

export interface Modalv2Props extends ModalProps {
  /**
   * A title for the modal header
   */
  title: string;
  /**
   * Children to render in the modal body
   */
  children: ReactNode;

  /**
   * Content for the modal footer, usually for modal action buttons
   */
  footer?: ReactNode;

  width?: string | number;

  fullScreen?: boolean;

  // used for Heap product analytics
  analyticsStackName?: string;

  /**
   * When passed, the modal will show a confirmation dialog when the user tries
   * to close the modal if this state has changed.
   */
  onCloseStateToConfirm?: StateToConfirm;
}

/**
 * Renders a Chakra UI modal with our opinionated configuration.
 * https://chakra-ui.com/docs/overlay/modal
 *
 * You'll need to use the `useDisclosure` hook to open and close the modal.
 * ```ts
 *  const { isOpen, onOpen, onClose } = useDisclosure()
 * ```
 * See usage https://chakra-ui.com/docs/components/overlay/modal#usage
 */
export function ModalV2({
  title,
  children,
  footer,
  width = 768,
  fullScreen,
  analyticsStackName,
  ...props
}: Modalv2Props) {
  const callback = useConfirmOnStateChange({
    state: props.onCloseStateToConfirm,
    callback: props.onClose,
    enabled: props.isOpen,
  });

  // don't render, so we can ensure the modal is closed when the component unmounts
  if (!props.isOpen) return null;

  const styles = fullScreen
    ? {
        width: "calc(100vw - 1rem)",
        height: "calc(100vh - 1rem)",
        maxHeight: "auto",
        maxWidth: "auto",
      }
    : {
        width,
        maxWidth: "100%",
      };

  return (
    <AnalyticsStack value={analyticsStackName}>
      <Modal {...props} onClose={callback} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent sx={styles}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Suspense fallback={<AnimatedZapLogo />}>
              <ErrorBoundary>{children}</ErrorBoundary>
            </Suspense>
          </ModalBody>
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContent>
      </Modal>
    </AnalyticsStack>
  );
}

interface ModalContext {
  setModal: (modal: ReactNode) => void;
  close: () => void;
}

const ModalContext = createContext<ModalContext>({
  setModal() {
    throw new Error("ModalContext not set");
  },
  close() {
    throw new Error("ModalContext not set");
  },
});

/**
 * Enables showing chakra modals via a hook.
 *
 * @example
 * Wrap your app with the modal context provider
 * ```tsx
 * import { ModalV2Provider } from "../ui/ModalV2";
 * function App() {
 *   return (
 *     <ModalV2Provider>
 *       <AppContent />
 *     </ModalV2Provider>
 *   );
 * }
 * ```
 *
 * Then use the `useModal` hook to open and close the modal.
 * ```tsx
 * import { useModal } from "../ui/Modalv2";
 *
 * function MyComponent() {
 *   const {confirm} = useModal();
 *
 *   function onItemDelete() {
 *     confirm({
 *       title: "Delete item",
 *       text: "Are you sure you want to delete this item?",
 *       onConfirm() {
 *         // do the deletion
 *       }
 *     });
 *   }
 *
 *  return (...)
 * }
 */
export function ModalV2Provider({ children }: { children: ReactNode }) {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const { route } = useRoute();

  const close = useCallback(() => {
    setModal(null);
  }, [setModal]);

  // Close modal when route changes
  useEffect(() => {
    close();
  }, [route?.name]);

  const value = {
    close,
    setModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
}

interface ConfirmProps {
  /**
   * Aria label for the modal, shown in the top of the modal header.
   */
  label: string;

  /**
   * Heading, within modal body, shown above the text.
   */
  title?: string;

  /**
   * The text to display in the modal body.
   * Though it accepts ReactNode, avoid nesting a `p` tag since it's wrapped in a `p` tag already.
   */
  text: ReactNode;

  /**
   * Whether to show a loading spinner on the confirm button.
   */
  loading?: boolean;

  /**
   * Callback to run when the confirm button is clicked.
   */
  onConfirm: () => void | Promise<unknown>;

  /**
   * Label for the confirm button.
   */
  confirmLabel?: string;

  /**
   * Variant for the confirm button.
   */
  confirmVariant?: "primary" | "danger";
}

function useConfirmModal() {
  const { setModal, close } = useContext(ModalContext);

  const confirm = useCallback(
    (props: ConfirmProps) => {
      const actions = (
        <InlineButtons>
          <Button
            variant="tertiary"
            onClick={close}
            analyticsName="confirm-modal-cancel"
          >
            Cancel
          </Button>
          <Button
            isDisabled={props.loading}
            isLoading={props.loading}
            onClick={() => {
              Promise.resolve(props.onConfirm()).finally(() => {
                close();
              });
            }}
            analyticsName="confirm-modal-submit"
            variant={props.confirmVariant || "primary"}
          >
            {props.confirmLabel || "Confirm"}
          </Button>
        </InlineButtons>
      );

      setModal(
        <ModalV2
          title={props.label}
          isOpen={true}
          onClose={close}
          footer={actions}
        >
          {props.title && (
            <Heading fontSize="fdy_xl" mb={4}>
              {props.title}
            </Heading>
          )}
          <Text>{props.text}</Text>
        </ModalV2>
      );
    },
    [setModal]
  );

  return {
    confirm,
  };
}

export function useModalV2() {
  const { confirm } = useConfirmModal();

  return {
    confirm,
  };
}
