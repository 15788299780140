import { percent } from "../../utils/formatters";
import { PersonaSetFragment } from "./__generated__/PersonaSetFragment";
import { AnalysisDimensionRow } from "./analysis/types";

// When a label like "65+" is present, some spreadsheet editors hide the "+"
// sign, so add "over" to make it clear and convince the editor to show the
// full label since it's text.
function formatMaxBinLabel(label: string) {
  return label.endsWith("+") ? `over ${label}` : label;
}

const EMPTY_CELL = "";
const SPACER_ROW: [] = [];

const modelingFieldToTraitName = (field: string) => {
  return field.replace(/^FIG_/, "fig/").toLowerCase();
};

/**
 * Converts Personas and Dimensions data to CSV format
 */
export function personaSetAnalysisToCSV(
  analysisDimensions: AnalysisDimensionRow[],
  personaSet: PersonaSetFragment
): string {
  const { personas, modelingFields } = personaSet;
  const modelingFieldNames = modelingFields.map(modelingFieldToTraitName);

  const sortedDimensions = [...analysisDimensions].sort((a, b) => {
    const aIsClustering = modelingFieldNames.includes(a.trait.name);
    const bIsClustering = modelingFieldNames.includes(b.trait.name);

    if (aIsClustering && !bIsClustering) return -1;
    if (!aIsClustering && bIsClustering) return 1;
    return a.trait.name.localeCompare(b.trait.name);
  });

  const headers = [EMPTY_CELL, EMPTY_CELL, ...personas.map((p) => p.name)];
  const rows = [headers];

  const individualsRow = [
    "Individuals",
    EMPTY_CELL,
    ...personas.map((p) => p.individualsCount?.toString() || ""),
  ];
  rows.push(individualsRow);

  const totalPersonas = personas.reduce(
    (sum, p) => sum + (p.individualsCount ?? 0),
    0
  );
  const totalRow = [
    "% of total persona set",
    EMPTY_CELL,
    ...personas.map((p) => percent((p.individualsCount ?? 0) / totalPersonas)),
  ];
  rows.push(totalRow);
  rows.push(SPACER_ROW);

  for (const dim of sortedDimensions) {
    const { name, literate } = dim.trait;
    const traitLabel = literate ? `${literate} (${name})` : name;

    const toprow = [
      traitLabel,
      EMPTY_CELL,
      // add common value for each persona
      ...dim.personas.map((p) => formatMaxBinLabel(p.common)),
    ];
    rows.push(toprow);

    for (const b of dim.personas[0].bins) {
      const binRow = [
        EMPTY_CELL,
        formatMaxBinLabel(b.label),
        ...dim.personas.map((p) => {
          const bin = p.bins.find((x) => x.label === b.label);
          return bin?.count.toString() ?? "";
        }),
      ];
      rows.push(binRow);
    }

    rows.push(SPACER_ROW);
  }

  const csvContent = rows
    // escape values with double quotes to prevent extra columns caused by commas
    .map((row) => row.map((value) => `"${value}"`).join(","))
    .join("\n");
  return csvContent;
}
