/** convert bytes into human readable kb, mb, and gb */
export function bytesToFileSize(bytes: number) {
  if (bytes >= 1024 * 1024 * 1024) {
    const sizeInGB = bytes / (1024 * 1024 * 1024);
    return `${parseFloat(sizeInGB.toFixed(2))} GB`;
  }
  if (bytes >= 1024 * 1024) {
    const sizeInMB = bytes / (1024 * 1024);
    return `${parseFloat(sizeInMB.toFixed(2))} MB`;
  }

  const sizeInKB = bytes / 1024;
  return `${parseFloat(sizeInKB.toFixed(2))} KB`;
}
