import { Upload } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { useError } from "react-use";

import { useToast } from "../../../../hooks/useToast";
import { plural } from "../../../../utils/formatters";
import { validateCSVHeadersMatch } from "../../../../utils/validateCSVHeadersMatch";
import { Button } from "../../../ui/Button";
import { CardV2 } from "../../../ui/Card/CardV2";
import { FormField } from "../../../ui/FormField";
import { MultiFileDropZone } from "../../../ui/MultiFileDropZone";
import { DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv } from "../../__generated__/DatasetShowPageQuery";
import { useUploadableFiles } from "../../shared/useMultiFileUpload";
import { uploadQueryKeys } from "./DatasetsDataCsvFilesTable";

export function DatasetsFilesUploadCard({
  options,
}: {
  options: DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv;
}) {
  const dispatchError = useError();
  const toast = useToast();
  const { files, setFiles, uploadFiles, someFilesUploading } =
    useUploadableFiles(options.uploadDirectory);
  const client = useQueryClient();

  async function onSubmit() {
    try {
      const filesError = await validateCSVHeadersMatch(
        files.map((f) => f.file)
      );
      if (filesError) {
        toast({
          title: "Cannot upload files",
          description: filesError,
          status: "error",
        });
        return;
      }

      await uploadFiles();

      toast({
        title: "Upload complete",
        description: "Your dataset will rebuild using the new files soon.",
        status: "success",
      });

      client.invalidateQueries({
        queryKey: uploadQueryKeys.uploadedFiles(options.uploadDirectory),
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatchError(err as Error);
      } else {
        throw err;
      }
    }
  }

  return (
    <CardV2
      title="Upload more data"
      text={
        <>
          Uploading new files will cause the dataset to rebuild with the new
          files.{" "}
          <strong>
            Files will need to match the same format and headings used as
            previous uploads.
          </strong>
        </>
      }
    >
      <FormField label="Upload CSV file(s)" visuallyHiddenLabel>
        <MultiFileDropZone files={files} onFilesChange={setFiles} required />
      </FormField>

      <Button
        mt={4}
        variant="secondary"
        isLoading={someFilesUploading}
        loadingText="Uploading..."
        isDisabled={!files.length}
        onClick={onSubmit}
        width="100%"
        analyticsName="upload-csv-file"
        leftIcon={<Upload />}
      >
        {files.length
          ? `Upload ${files.length} ${plural(files.length, "file", "files")}`
          : "Upload files"}
      </Button>
    </CardV2>
  );
}
