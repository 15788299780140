import { Flex } from "@chakra-ui/react";

import { TagList } from "../../../../ui/TagList";
import { DatasetColumn } from "../shared/DatasetColumn";
import { DatasetEventConfig } from "./DatasetsEventsCard";

const plural = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;

export function EventPropertyTeaser({ event }: { event: DatasetEventConfig }) {
  const { data_map: dataMap, conditions } = event;
  const { value, datetime, ...custom } = dataMap;

  const customPropertiesCount = Object.keys(custom).length;

  return (
    <TagList>
      {datetime?.column_name && (
        <TagList.Item>
          <Flex gap={1}>
            datetime
            <DatasetColumn columnName={datetime.column_name} />
          </Flex>
        </TagList.Item>
      )}

      {value?.column_name && (
        <TagList.Item>
          <Flex gap={1}>
            value
            <DatasetColumn columnName={value.column_name} />
          </Flex>
        </TagList.Item>
      )}

      {customPropertiesCount > 0 ? (
        <TagList.Item>
          {customPropertiesCount}{" "}
          {plural(customPropertiesCount, "property", "properties")}
        </TagList.Item>
      ) : null}

      {conditions.length > 0 ? (
        <TagList.Item>
          {conditions.length}{" "}
          {plural(conditions.length, "condition", "conditions")}
        </TagList.Item>
      ) : null}
    </TagList>
  );
}
