import { AccountConfigMap } from "../../../../../hooks/accountConfigHooks";
import { DetectedColumn } from "../shared/types";
import { IdentitySetState } from "./DatasetsIdentitySetsModal";
import {
  IDENTITY_PROPERTY_LABELS,
  IdentityProperties,
  IdentityPropertiesState,
  IdentityPropertyName,
} from "./DatasetsIdentitySetsPropertiesTable";

export type IdentitySetErrors = {
  name?: string;
  properties?: string;
};

export function propertiesFromWire(
  properties: IdentityProperties
): IdentityPropertiesState {
  const { house_number_and_street, ...rest } = properties;
  return {
    ...rest,
    address_1: house_number_and_street?.[0],
    address_2: house_number_and_street?.[1],
  };
}

export function propertiesToWire(
  propertiesState: IdentityPropertiesState
): IdentityProperties {
  // take address lines so they are omitted from the rest before returning
  const { address_1, address_2, ...rest } = propertiesState;

  const addr = [];
  if (address_1) addr.push(address_1);
  if (address_2) addr.push(address_2);

  return {
    ...rest,
    house_number_and_street: addr,
  };
}

// Checks that user is entering an identity that we can match with
// different variations of required PII to match.
function hasIdentityPropertySet(properties: IdentityProperties) {
  // ensure keys are those of populated values
  const keys = Object.keys(properties).filter(
    (key) => properties[key as keyof IdentityProperties]
  ) as (keyof IdentityProperties)[];

  const hasPhoneOrEmail =
    keys.includes("email") ||
    keys.includes("phone") ||
    keys.includes("email_hash");

  const hasName =
    (keys.includes("person_first_name") && keys.includes("person_last_name")) ||
    keys.includes("person_full_name");

  // either has freeform address, address + zip, or address + city + state
  const hasAddress =
    keys.includes("freeform_address") ||
    (keys.includes("house_number_and_street") &&
      (keys.includes("postcode") ||
        (keys.includes("city") && keys.includes("state"))));

  return hasPhoneOrEmail || (hasName && hasAddress);
}

export const nameHelpText =
  "Name must only contain lowercase letters, numbers, and underscores. Must begin with a letter or underscore.";
export const identitySetNamePattern = /^[a-z_][a-z0-9_]*$/;

export function validateIdentitySet(
  state: IdentitySetState,
  config: AccountConfigMap
) {
  const { name, properties } = state;

  const errors: IdentitySetErrors = {};

  const trimmedName = name.trim();

  if (!trimmedName) {
    errors.name = "Name is required";
  } else if (!trimmedName.match(identitySetNamePattern)) {
    errors.name = nameHelpText;
  }

  const addressOnlyDataAccount = config["address_only_data.enabled"];
  const propertiesForWire = propertiesToWire(properties);

  if (Object.values(properties).filter((v) => v).length === 0) {
    errors.properties =
      "Please include at least one column for the identity set";
  } else if (properties.address_2 && !properties.address_1) {
    errors.properties = "Address line 2 cannot be set without address line 1";
  } else if (
    properties.freeform_address &&
    (properties.address_1 ||
      properties.address_2 ||
      properties.city ||
      properties.state ||
      properties.postcode)
  ) {
    errors.properties =
      "Cannot set freeform address and individual address fields at the same time";
  } else if (
    // If we only handle address data - don't do an identity set check, if there is incomplete
    // data the dataset itself will raise a user friendly error.
    !addressOnlyDataAccount &&
    !hasIdentityPropertySet(propertiesForWire)
  ) {
    errors.properties =
      "Email/email hash, or phone, or name and address is required";
  }

  console.log(errors);

  return Object.values(errors).length ? errors : undefined;
}

// Given a property name for the local state field (address_1, address_2),
// return the property name for the wire format (house_number_and_street).
// TODO: we should change the API to accept house_number_and_street as separate fields (address_1, address_2)
export const getPropertyStateName = (propertyName: string) => {
  return propertyName === "address_1"
    ? "house_number_and_street"
    : propertyName;
};

// Given initial state and detected columns, returns the properties map
// for the identity set with the columns filled in based on recommended
// identifier columns.
export function createPrefilledProperties(
  initialState: IdentitySetState,
  detectedColumns: DetectedColumn[]
): {
  state: IdentityPropertiesState;
  prefilledProperties: string[];
} {
  // if we have an initial state, use that
  if (Object.entries(initialState.properties).length) {
    return {
      state: initialState.properties,
      prefilledProperties: [],
    };
  }

  // otherwise, fill in the properties based on detected column recommendations
  const properties: IdentityPropertiesState = {};
  const prefilledProperties: string[] = [];

  for (const propertyName of Object.keys(IDENTITY_PROPERTY_LABELS)) {
    const column = detectedColumns.find((col) =>
      col.identifierRecommendation.includes(getPropertyStateName(propertyName))
    );

    if (column) {
      properties[propertyName as IdentityPropertyName] = column.name;
      prefilledProperties.push(column.name);
    }
  }

  return { state: properties, prefilledProperties };
}
