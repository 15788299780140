import { Skeleton, Tag } from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { Lock } from "@phosphor-icons/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { MutedText } from "../../ui/MutedText";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import { CohortActionsMenu } from "../CohortActionsMenu";
import { CohortExploreSwitch } from "../CohortExploreSwitch";
import { CohortEventSummary } from "../CohortForm/CohortEventsCard/CohortEventSummary";
import { CohortTraitTags } from "../CohortTraitTags";
import {
  CohortsListPageQuery,
  CohortsListPageQuery_cohorts,
} from "./__generated__/CohortsListPageQuery";
import { Cohort, cohortsListPageQuery } from "./CohortsListPage";

function LockTag() {
  return (
    <Tag>
      <Lock style={{ marginRight: 4 }} />
      <span>Custom</span>
    </Tag>
  );
}

function CohortCount({
  count,
  status,
}: {
  count: number | null | undefined;
  status: ResourceStatus;
}) {
  if (status === ResourceStatus.STARTING || status === ResourceStatus.RUNNING) {
    return <span>Calculating...</span>;
  }

  if (
    status === ResourceStatus.ERROR ||
    count === undefined ||
    count === null
  ) {
    return null;
  }

  return <span>{count.toLocaleString()}</span>;
}

const BLANK_SLATE_PROPS = {
  title: "No Cohorts found",
  text: "Cohorts are based on Event Streams and sometimes Traits, which are emitted by Datasets. They are used to create Outcomes and Pipelines.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Start by adding a Dataset if you haven't already.",
  },
};

export const showInExploreTooltip =
  "Toggle a resource to enable it in Explore. Explore lets you use traits to compare your cohorts, persona sets, and to the U.S. population baseline to each other.";

export function CohortsTable() {
  const config = useAccountConfigMap();

  // query all cohorts
  const { data, loading, error } = useSojournerQuery<CohortsListPageQuery>(
    cohortsListPageQuery,
    {
      pollInterval: 10000,
    }
  );

  if (error) throw error;

  // Because our `GET /cohorts` does not send the meta info about traits
  // along with the trait _conditions_, we need to query all traits so we can join on client side
  // to get meta info about the traits, like literate.
  const { traitsMap, loadingTraits } = useTraitsQuery();

  const tableColumns: ColumnDef<CohortsListPageQuery_cohorts>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        accessorKey: "populationCount",
        header: "Individuals",
        sortingFn: "basic",
        cell: ({ row }) => (
          <CohortCount
            count={row.original.populationCount}
            status={row.original.status}
          />
        ),
      },
      {
        accessorKey: "streamName",
        header: "Event",
        sortingFn: "basic",
        cell: ({ row }) => {
          const cohort = row.original;

          return cohort.classic ? (
            <LockTag />
          ) : cohort.streamName ? (
            <CohortEventSummary
              showStreamName
              event={{
                ...cohort,
                ...cohort.recency,
              }}
            />
          ) : (
            <MutedText>None</MutedText>
          );
        },
      },
      {
        accessorKey: "traits",
        header: "Traits",
        size: 200,
        cell: ({ row }) => {
          if (row.original.classic) return <LockTag />;

          if (row.original.traits.length === 0)
            return <MutedText>None</MutedText>;

          if (loadingTraits) return <Skeleton height="20px" width="100px" />;

          return (
            <CohortTraitTags
              conditions={row.original.traits}
              traits={traitsMap}
            />
          );
        },
      },
      {
        accessorKey: "placeConditions",
        header: "Places",
        cell: ({ row }) => (
          <>
            {row.original.placeConditions.length || <MutedText>None</MutedText>}
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        sortingFn: "basic",
        cell: ({ row }) => <ResourceStatusBadge resource={row.original} />,
      },
      {
        accessorKey: "createdAt",
        header: () => "Created",
        sortingFn: "datetime",
        cell: ({ row }) => <TimeAgo date={row.original.createdAt} />,
      },
      {
        accessorKey: "lastUpdatedConfigAt",
        header: "Modified",
        sortingFn: "datetime",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedConfigAt} />,
      },
      {
        accessorKey: "lastUpdatedOutputAt",
        header: "Processed",
        sortingFn: "datetime",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedOutputAt} />,
      },
      {
        header: "Explore",
        accessorKey: "explore",
        enableSorting: false,
        meta: {
          headerTooltip: showInExploreTooltip,
        },
        cell: ({ row }) => <CohortExploreSwitch cohort={row.original} />,
      },
      {
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <CohortActionsMenu
            cohort={row.original}
            options={{
              view: true,
              rename: row.original.classic !== true,
              api: true,
              duplicate: true,
              delete: true,
              archive: true,
            }}
            buttonProps={{ variant: "icon", my: -2 }}
          />
        ),
      },
    ],
    [traitsMap, loadingTraits]
  );

  return (
    <ResourceTable<Cohort>
      resourceType={ResourceType.Cohorts}
      blankslate={BLANK_SLATE_PROPS}
      loading={loading}
      data={data?.cohorts ?? []}
      columns={tableColumns}
      columnVisibility={{
        // Only show Places to users that have them preconfigured
        // (so we don't busy the listing page for general users).
        places: data?.cohorts.some((c) => c.placeConditions.length) ?? false,
        explore: config["explore.enabled"],
      }}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
    />
  );
}
