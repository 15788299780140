import { StreamAnalysisTimeSeriesDatum } from "@fdy/faraday-js";

import { yearMonthDaySlug } from "../../../utils/formatters";
import { StreamAnalysisTimeFrame } from "./EventStreamAnalysisCard";

/**
 * Filters the data based on the given time frame.
 */
function filterByTimeframe(
  data: StreamAnalysisTimeSeriesDatum[],
  timeFrame: StreamAnalysisTimeFrame,
  today: Date
) {
  if (timeFrame === StreamAnalysisTimeFrame.LastThreeYears) {
    const threeYearsAgo = new Date(today);
    threeYearsAgo.setFullYear(today.getFullYear() - 3);
    return data.filter((event) => new Date(event.date) >= threeYearsAgo);
  }
  return data;
}

/**
 * Injects empty datums where there are missing dates in the time series data.
 * Also ensure the end of the time series is today, so users can see that there
 * there is missing data.
 *
 * TODO: we should use a `type: 'time'` scale instead of injecting intermediate
 * dates. We should only have to inject the recent empty data with this fn.
 * Changing the chart type would mean a bunch of other refactoring, so I'm
 * leaving it for now.
 */
function injectMissingDates(
  data: StreamAnalysisTimeSeriesDatum[],
  today: Date
) {
  const sortedData = [...data].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  // get the earliest date in the data
  const first = sortedData.at(0)?.date;
  if (!first) return sortedData;

  // for each month between the first and today, inject a datum if it doesn't exist
  const missingDates: StreamAnalysisTimeSeriesDatum[] = [];
  const startDate = new Date(first);
  const endDate = new Date(today);
  endDate.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  while (startDate <= endDate) {
    const dateString = yearMonthDaySlug(startDate);
    if (!data.some((datum) => datum.date === dateString)) {
      missingDates.push({
        date: dateString,
        count: 0,
      });
    }
    startDate.setDate(startDate.getDate() + 1);
  }
  return [...sortedData, ...missingDates].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
}

/**
 * Prepares time series data by filtering based on the given time frame and
 * ensuring today's date is included if not already present.
 */
export function prepareTimeSeriesData(
  data: StreamAnalysisTimeSeriesDatum[],
  timeFrame: StreamAnalysisTimeFrame
) {
  const today = new Date();
  const result = filterByTimeframe(data, timeFrame, today);
  return injectMissingDates(result, today);
}
