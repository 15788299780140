import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { useToast } from "../../../../hooks/useToast";
import { useFdyClient } from "../../../../services/FdyClientProvider";
import { downloadFile } from "../../../../utils/downloadFile";
import { useModalV2 } from "../../../ui/ModalV2";

type DeleteUploadArgs = {
  directory: string;
  filename: string;
};

export function CsvActionsMenu({ filePath }: { filePath: string }) {
  const toast = useToast();
  const { confirm } = useModalV2();
  const [downloading, setDownloading] = useState<boolean>(false);

  const client = useFdyClient();

  const deleteMutation = useMutation({
    mutationFn: ({ directory, filename }: DeleteUploadArgs) =>
      client.uploads.deleteUpload(directory, filename),
    // We rely on global react query cache to invalid queries, which'll cause the
    // file list query to refetch.
  });

  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const label = `Toggle ${filePath} actions`;

  const directory = filePath.split("/")[0];
  const filename = filePath.split("/")[1];

  function handleDeleteConfirm() {
    toast.promise(deleteMutation.mutateAsync({ directory, filename }), {
      loading: {
        title: `Deleting ${filename}...`,
      },
      success: {
        title: "File deleted",
        description: `The file ${filename} has been deleted.`,
      },
      error: {
        title: `Failed to delete ${filename}`,
        description: "An error occurred while deleting the file.",
      },
    });
  }

  function handleDeleteClick() {
    confirm({
      label: "Delete file",
      text: (
        <>
          Are you sure you want to permanently delete{" "}
          <strong>{filename}</strong>? Removing files that are potentially in
          use by downstream resources can cause errors. If in doubt, contact
          support.
        </>
      ),
      onConfirm: handleDeleteConfirm,
      confirmLabel: "Delete uploaded file",
      confirmVariant: "danger",
    });
  }

  async function handleDownload() {
    setDownloading(true);

    // TODO: this could perhaps be an href link to the file and use a hidden
    // link to click? does that help at all for large files?
    const upload = await client.uploads.getUpload(directory, filename);
    const content = await upload.text();

    // the api returns the file with filename = fdysec, but downloadFile overwrites that
    const secureFilename = filename.includes("fdysec")
      ? filename
      : "fdysec_" + filename;
    downloadFile({ filename: secureFilename, content, type: "csv" });
    setDownloading(false);
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        ref={menuBtnRef}
        aria-label={label}
        icon={<DotsThreeOutline weight="fill" />}
        variant="secondary"
        color="fdy_gray.600"
        size="sm"
        isLoading={downloading || deleteMutation.isPending}
        isDisabled={downloading || deleteMutation.isPending}
      />
      <MenuList>
        <MenuItem onClick={handleDownload} disabled={downloading}>
          Download
        </MenuItem>
        <MenuItem
          onClick={handleDeleteClick}
          color="fdy_red.500"
          disabled={deleteMutation.isPending}
        >
          Delete...
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
