import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { useSojournerQuery } from "../../../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../../../ui/AnimatedZapLogo";
import { Redirect } from "../../../ui/Redirect";
import {
  DatasetShowPageQuery,
  DatasetShowPageQueryVariables,
} from "../../__generated__/DatasetShowPageQuery";
import { datasetShowPageQuery } from "../../sharedQueries";
import { DatasetLayout } from "../DatasetsLayout";
import { DatasetDataTabHosted } from "./DatasetsDataHostedCsv";
import { DatasetDataTabMerge } from "./DatasetsDataMerge";
import { DatasetDataTabOther } from "./DatasetsDataOther";

export function DatasetDataPage() {
  const { route } = useRoute();
  const id = route.params.id;

  const { data, loading } = useSojournerQuery<
    DatasetShowPageQuery,
    DatasetShowPageQueryVariables
  >(datasetShowPageQuery, {
    variables: {
      datasetId: id,
    },
  });

  if (loading) {
    return <AnimatedZapLogo />;
  }

  const dataset = data?.dataset;

  if (!dataset) {
    return <Redirect routeName={ROUTE_NAMES.DATASETS} showToast />;
  }

  return (
    <DatasetLayout dataset={dataset}>
      {dataset.options.__typename === "DatasetOptionsHostedCsv" ? (
        <DatasetDataTabHosted
          dataset={dataset}
          datasetOptions={dataset.options}
        />
      ) : dataset.options.__typename === "DatasetOptionsMerge" ? (
        // because of the type check above, we know dataset is a MergeDataset,
        // but typescript doesn't know that
        <DatasetDataTabMerge dataset={dataset} />
      ) : (
        <DatasetDataTabOther dataset={dataset} />
      )}
    </DatasetLayout>
  );
}
