import { gql } from "@apollo/client";

import { datasetOptionsFragment } from "../../connectionOptionsFragments";

export const datasetFragment = gql`
  fragment DatasetFragment on Dataset {
    id
    name
    managed
    archivedAt
    status
    statusError
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    lastReadInputAt
    createdAt
    referenceKeyColumn
    referenceKeyColumns
    upsertColumns
    detectedColumns {
      name
      fillRate
      dataType
      identifierRecommendation
    }
    sample
    updates {
      datetime
      rowsAdded
    }
    connectionId
    outputToStreams
    outputToTraits
    identitySets
    privacy
    mergeDatasets {
      datasetId
      joinColumn
    }
    enrichment
    rowCount
    identifiedCount
    matchedCount
    ...DatasetOptions
  }
  ${datasetOptionsFragment}
`;
