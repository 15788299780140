import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { percent } from "../../../../utils/formatters";
import { isPlainObject } from "../../../../utils/isPlainObject";
import { Blankslate } from "../../../ui/Blankslate";
import { CardV2 } from "../../../ui/Card/CardV2";
import { TagList } from "../../../ui/TagList";
import { DatasetShowPageQuery_dataset } from "../../__generated__/DatasetShowPageQuery";
import { isSampleData } from "../DatasetForm/shared/OptionWithSampleData";

export function DatasetSampleCard({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  const sample = isSampleData(dataset.sample) ? dataset.sample : null;

  if (!sample) {
    return (
      <CardV2 title="Sample">
        <Blankslate title="No sample data found" filled />
      </CardV2>
    );
  }

  const headers = Object.keys(sample);

  return (
    <CardV2
      title="Sample"
      text="This is a sample of the data we detected in this dataset. Values are randomly selected and may not be representative of the entire dataset."
    >
      <Table size="sm" fontFamily={"monospace"}>
        <Thead>
          <Tr>
            <Th>Column name</Th>
            <Th>Data type</Th>
            <Th>Fill rate</Th>
            <Th>Sample</Th>
          </Tr>
        </Thead>
        <Tbody>
          {headers.map((header, idx) => {
            const col = dataset.detectedColumns.find(
              (col) => col.name === header
            );

            const dataType =
              typeof col?.dataType === "string"
                ? col.dataType
                : isPlainObject(col?.dataType) &&
                  typeof col?.dataType.type === "string"
                ? col.dataType.type
                : "Unknown";

            return (
              <Tr key={idx}>
                <Td>{header}</Td>
                <Td>{dataType}</Td>
                <Td>{col?.fillRate ? percent(col.fillRate) : "N/A"}</Td>
                <Td>
                  <TagList tags={sample[header]} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </CardV2>
  );
}
