import { ButtonGroup, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Trait, TraitCategory } from "@fdy/faraday-js";
import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  useDatasetListSuspenseQuery,
  useTraitShowSuspenseQuery,
} from "../../hooks/api";
import { percent } from "../../utils/formatters";
import { PrimitiveDataTypePill } from "../events/EventShowPage/PrimitiveDataTypePill";
import { AlertStack } from "../ui/AlertStack";
import { Blankslate } from "../ui/Blankslate";
import { CardV2 } from "../ui/Card/CardV2";
import { CardStack } from "../ui/CardStack";
import { DependencyStatusAlert } from "../ui/DependencyStatusAlert";
import { MutedText } from "../ui/MutedText";
import { PageContainer } from "../ui/PageContainer";
import { PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { ProviderPill } from "../ui/ProviderPill";
import { ResourceDocsLink } from "../ui/ResourceDocsLink";
import { ResourceGraphCard } from "../ui/ResourceGraphCard";
import { ResourceLink } from "../ui/ResourceLink";
import { TagList } from "../ui/TagList";
import { TraitActionsMenu } from "./TraitActionsMenu";
import { TraitAnalysisDimensionsChart } from "./TraitAnalysisDimensionsChart";
import { TraitPermissionsPills } from "./TraitPermissionsPills";
import {
  traitCategoryToLiterate,
  traitLiterate,
  traitTierToLiterate,
} from "./traitUtils";

function TraitSummaryCard({ trait }: { trait: Trait }) {
  return (
    <CardV2 title="Definition" text={trait.description}>
      <Table
        size="sm"
        sx={{
          th: { width: 180, p: 2 },
        }}
      >
        <Tbody>
          {trait.category && (
            <Tr>
              <Th>Provider</Th>
              <Td>
                <ProviderPill provider={trait.category} />
              </Td>
            </Tr>
          )}

          {trait.category && (
            <Tr>
              <Th>Category</Th>
              <Td>{traitCategoryToLiterate(trait.category)}</Td>
            </Tr>
          )}

          {trait.type && (
            <Tr>
              <Th>Type</Th>
              <Td>
                <PrimitiveDataTypePill type={trait.type} />
              </Td>
            </Tr>
          )}

          {trait.coverage !== undefined && (
            <Tr>
              <Th>Coverage</Th>
              <Td>{percent(trait.coverage)}</Td>
            </Tr>
          )}

          {trait.statistical_type && (
            <Tr>
              <Th>Statistical type</Th>
              <Td>{trait.statistical_type}</Td>
            </Tr>
          )}

          {trait.categories && trait.categories.length > 0 && (
            <Tr>
              <Th>Categories</Th>
              <Td>
                <TagList tags={trait.categories ?? []} />
              </Td>
            </Tr>
          )}

          {trait.breaks && (
            <Tr>
              <Th>Breaks</Th>
              <Td>
                {/* use lookup table if needed */}
                <TagList
                  tags={
                    trait.breaks?.map((b) => {
                      const lookupVal = trait.lookup_table?.[b as keyof object];
                      if (lookupVal) return `${b} (${lookupVal})`;
                      return b;
                    }) ?? []
                  }
                />
              </Td>
            </Tr>
          )}

          {trait.tier && (
            <Tr>
              <Th>Tier</Th>
              <Td>{traitTierToLiterate(trait.tier)}</Td>
            </Tr>
          )}

          {trait.permissions && trait.permissions.length > 0 && (
            <Tr>
              <Th>Permissions</Th>
              <Td>
                <TraitPermissionsPills permissions={trait.permissions} />
              </Td>
            </Tr>
          )}

          {trait.deprecated && (
            <Tr>
              <Th>Deprecated</Th>
              <Td>Yes</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </CardV2>
  );
}

function TraitAnalysisCard({ trait }: { trait: Trait }) {
  return (
    <CardV2 title="Analysis">
      <TraitAnalysisDimensionsChart trait={trait} />
    </CardV2>
  );
}

const TraitDatasetsTable = ({ trait }: { trait: Trait }) => {
  const { data: datasets } = useDatasetListSuspenseQuery();

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Dataset</Th>
          <Th>Column name</Th>
          <Th>Format</Th>
        </Tr>
      </Thead>
      <Tbody>
        {trait.emitted_by_datasets?.map((emit) => {
          const dataset = datasets.find((d) => d.id === emit.dataset_id);

          return (
            <Tr key={emit.dataset_id}>
              <Td>
                {dataset ? (
                  <ResourceLink resource={dataset} showResourceIcon />
                ) : (
                  <MutedText>Dataset not found {emit.dataset_id}</MutedText>
                )}
              </Td>
              <Td>{emit.column_name}</Td>
              <Td>{emit.format}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

function TraitEmittedByDatasetsCard({ trait }: { trait: Trait }) {
  const emittedDatasets = trait.emitted_by_datasets ?? [];
  return (
    <CardV2 title="Contribution">
      {emittedDatasets.length > 0 ? (
        <TraitDatasetsTable trait={trait} />
      ) : (
        <Blankslate
          title="Trait is not being emitted by any datasets"
          text="No data is backing this trait. It is safe to delete or archive this trait if it is no longer needed."
        />
      )}
    </CardV2>
  );
}

export function TraitsShowPage() {
  const { route } = useRoute();
  const { data: trait } = useTraitShowSuspenseQuery(route.params.id);

  const actions = (
    <ButtonGroup>
      <ResourceDocsLink resourceType="traits" size="sm" />
      <TraitActionsMenu trait={trait} />
    </ButtonGroup>
  );

  return (
    <PageLayout>
      <PageHeader
        title={traitLiterate(trait)}
        backBtnProps={{
          label: "Traits",
          routeName: ROUTE_NAMES.TRAITS,
        }}
        crumbs={[
          {
            label: "Traits",
            routeName: ROUTE_NAMES.TRAITS,
          },
          {
            label: traitLiterate(trait),
            routeName: ROUTE_NAMES.TRAITS_SHOW,
            params: { id: trait.id },
          },
        ]}
        rightContent={actions}
      />
      <PageContainer>
        <AlertStack>
          {trait.category === TraitCategory.UserDefined && (
            <DependencyStatusAlert resource={trait} />
          )}
          {/* traits don't have job state emitted on API. Should they? */}
          {/* <ResourceStatusAlert resource={trait} /> */}
        </AlertStack>

        <CardStack>
          <TraitSummaryCard trait={trait} />

          {trait.category !== TraitCategory.UserDefined && (
            <TraitAnalysisCard trait={trait} />
          )}

          {trait.category === TraitCategory.UserDefined && (
            <TraitEmittedByDatasetsCard trait={trait} />
          )}

          {trait.category === TraitCategory.UserDefined && (
            <ResourceGraphCard resourceId={trait.id} />
          )}
        </CardStack>
      </PageContainer>
    </PageLayout>
  );
}
