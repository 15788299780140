import { useFormControlContext } from "@chakra-ui/react";
import { useMemo } from "react";
import Select from "react-select";

import {
  analyticsAttrs,
  useAnalyticsKey,
} from "../../../../ui/Analytics/AnalyticsStack";
import {
  DatasetSelectOption,
  reactSelectStyle,
} from "../../../shared/reactSelectStyle";
import { getPropertyStateName } from "../identity/identitySetUtils";
import {
  formatOptionLabel,
  hintFromSampleData,
  SampleData,
} from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";

export function DatasetColumnSelect({
  label,
  name,
  value,
  onChange,
  detectedColumns,
  sampleData,
  disabled,
  analyticsName,
  required,
  placeholder = "Select a column...",
}: {
  label?: string;
  name?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  detectedColumns: DetectedColumn[];
  sampleData: SampleData | undefined;
  disabled?: boolean;
  analyticsName: string;
  required?: boolean;
  placeholder?: string;
}) {
  const analyticsKey = useAnalyticsKey(analyticsName);
  const controlContext = useFormControlContext();

  const columnOptions: DatasetSelectOption[] = useMemo(() => {
    return detectedColumns
      .map((column) => {
        return {
          value: column.name,
          label: column.name,
          hint: hintFromSampleData({ sampleData, column }),
          fillRate: column.fillRate,
          isRecommended: name
            ? // TODO: this shouldn't be undefined?
              column.identifierRecommendation?.includes(
                getPropertyStateName(name)
              )
            : false,
        };
      })
      .sort((a, b) => {
        // ok to sort by recommended at top because the api won't recommend 0 fill
        // rate columns anyway
        if (a.isRecommended && !b.isRecommended) return -1;
        if (!a.isRecommended && b.isRecommended) return 1;
        if (a.fillRate === b.fillRate) return a.label.localeCompare(b.label);
        return (b.fillRate ?? 0) - (a.fillRate ?? 0);
      });
  }, [detectedColumns, sampleData, name]);

  function handleChangeColumn(
    newValue: { value: string; label: string } | null
  ): void {
    onChange(newValue?.value ?? null);
  }

  // use nullish coalescing here because react selcet prefers it for 'empty' value instead of undefined
  const selectedOption =
    columnOptions.find((opt) => opt.value === value) ?? null;

  return (
    <Select<DatasetSelectOption>
      name={name}
      aria-label={label}
      placeholder={placeholder}
      isClearable
      options={columnOptions}
      value={selectedOption}
      onChange={handleChangeColumn}
      isDisabled={disabled}
      styles={reactSelectStyle}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      required={required}
      inputId={controlContext?.id}
      {...analyticsAttrs(analyticsKey)}
    />
  );
}
