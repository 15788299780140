import { Flex, FlexProps } from "@chakra-ui/react";
import * as React from "react";

export function IconWithText({ children, ...props }: FlexProps) {
  return (
    <Flex gap={1} alignItems="center" {...props}>
      {children}
    </Flex>
  );
}
