import { Text } from "@chakra-ui/react";
import { DatasetEnrichment, DatasetEnrichments } from "@fdy/faraday-js";

import { useAdmin } from "../../../hooks/useAdmin";
import { number, percent } from "../../../utils/formatters";
import { CardV2 } from "../../ui/Card/CardV2";
import { PendingValue } from "../../ui/PendingValue";
import {
  SummaryGroup,
  SummaryItem,
  SummarySublabel,
} from "../../ui/SummaryCard";
import { datasetTooltips } from "../DatasetsListPage/datasetTooltips";
import { DatasetFormDataset } from "./DatasetForm/DatasetForm";

export function DatasetSummaryCard({
  dataset,
}: {
  dataset: DatasetFormDataset;
}) {
  const { admin } = useAdmin();
  const enrichment = dataset.enrichment as unknown as DatasetEnrichments | null;
  const { person, residence } = (enrichment?.fig as DatasetEnrichment) ?? {
    person: 0,
    residence: 0,
  };
  const { identifiedCount, rowCount, matchedCount } = dataset;

  if (identifiedCount === null || rowCount === null || matchedCount === null) {
    return null;
  }

  return (
    <CardV2
      title="Summary"
      text="Results of your imported dataset based on configuration."
    >
      <SummaryGroup>
        <SummaryItem title="Rows" tooltipValue={datasetTooltips.count}>
          <PendingValue value={rowCount} formatter={number} />
        </SummaryItem>
        <SummaryItem
          title="Identified people"
          tooltipValue={datasetTooltips.count}
        >
          <PendingValue value={identifiedCount} formatter={number} />
        </SummaryItem>
        <SummaryItem title="Matched" tooltipValue={datasetTooltips.matchRate}>
          <PendingValue value={matchedCount} formatter={number} />
          <SummarySublabel>
            {percent(matchedCount / identifiedCount)}
          </SummarySublabel>
        </SummaryItem>
        {/* cxo's not sure we want this shown to clients much yet */}
        {admin && (
          <>
            <SummaryItem
              title="Enriched residences"
              tooltipValue={datasetTooltips.enrichment}
            >
              <PendingValue value={residence} formatter={number} />
              <SummarySublabel>
                {percent(residence / identifiedCount)}
              </SummarySublabel>
            </SummaryItem>
            <SummaryItem
              title="Enriched people"
              tooltipValue={datasetTooltips.enrichment}
            >
              <PendingValue value={person} formatter={number} />
              <SummarySublabel>
                {percent(person / identifiedCount)}
              </SummarySublabel>
            </SummaryItem>
          </>
        )}
      </SummaryGroup>

      {admin && (
        <Text
          sx={{
            color: "fdy_gray.600",
            textAlign: "right",
            mt: 2,
            fontStyle: "italic",
          }}
        >
          Enrichment data is only visible to admin or API users.
        </Text>
      )}
    </CardV2>
  );
}
