import {
  CloseButton,
  Flex,
  Grid,
  HStack,
  Spinner,
  Text,
  useFormControlContext,
  VStack,
} from "@chakra-ui/react";
import {
  CheckCircle,
  File,
  Upload,
  WarningCircle,
} from "@phosphor-icons/react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { colors } from "../../styles/chakra-theme-v2";
import { bytesToFileSize } from "../../utils/formatFileSize";
import {
  FileUploadState,
  FileWithStatus,
} from "../datasets/shared/useMultiFileUpload";

interface MultiFileDropZoneProps {
  /**
   * The current list of files with their upload statuses.
   */
  files: FileWithStatus[];
  /**
   * A mapping of MIME types to their accepted file extensions.
   * Defaults to accepting `.csv`, `.txt`, and `.gpg` files.
   */
  accept?: Record<string, string[]>;
  /**
   * The maximum number of files that can be uploaded.
   * Defaults to 20
   */
  maxFiles?: number;
  /**
   * The maximum size (in bytes) for a single file.
   * Defaults to 50GB.
   */
  maxSize?: number;
  /**
   * Callback function triggered when the file list changes.
   */
  onFilesChange: (files: FileWithStatus[]) => void;

  /**
   * Whether the dropzone is required.
   */
  required?: boolean;
}

interface FileStatusIconProps {
  file: FileWithStatus;
}

const FileStatusIcon = ({ file }: FileStatusIconProps) => {
  switch (file.status) {
    case FileUploadState.uploading:
      return <Spinner size="sm" />;
    case FileUploadState.done:
      return <CheckCircle size={18} color={colors.fdy_green[500]} />;
    case FileUploadState.error:
      return <WarningCircle size={18} color={colors.fdy_red[500]} />;
    default:
      return <File size={20} color={colors.fdy_gray[800]} />;
  }
};

/**
 * A React component for handling multiple file uploads with drag-and-drop functionality.
 * Intended to be paired with the `useUploadableFiles` hook for managing file upload states.
 *
 * @example
 * ```tsx
 * const { files, setFiles } = useUploadableFiles("my-upload-dir");
 *
 * <MultiFileDropZone
 *   files={files}
 *   onFilesChange={setFiles}
 *   accept={{
 *     "image/png": [".png"],
 *     "image/jpeg": [".jpg", ".jpeg"],
 *   }}
 *   maxFiles={10}
 *   maxSize={10 * 1024 * 1024} // 10MB
 * />
 * ```
 */
export const MultiFileDropZone = ({
  accept = {
    "text/csv": [".csv", ".CSV"],
    "text/plain": [".txt", ".TXT"],
    "application/pgp": [".gpg", ".GPG", ".pgp", ".PGP"],
  },
  maxFiles = 20,
  maxSize = 5 * 1024 * 1024 * 1024,
  files,
  onFilesChange,
  required,
}: MultiFileDropZoneProps) => {
  const controlContext = useFormControlContext();

  const textColor = "rgba(0, 0, 0, 0.6)";

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const newFiles: FileWithStatus[] = acceptedFiles
        // Filter out files that are already in the list
        .filter(
          (file) =>
            !files.some((existingFile) => existingFile.file.name === file.name)
        )
        .map((file) => ({
          file,
          status: FileUploadState.idle,
        }));

      const updatedFiles = [...files, ...newFiles].slice(0, maxFiles);

      onFilesChange(updatedFiles);
    },
    [files, maxFiles, onFilesChange]
  );

  const removeFile = (name: string) => {
    const newFiles = files.filter((file) => file.file.name !== name);
    onFilesChange(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
    maxFiles: maxFiles - files.length,
  });

  const dedupedExtensions = Array.from(
    new Set(
      Object.values(accept)
        .flat()
        .map((ext) => ext.toLowerCase())
    )
  );
  const fileTypesText = `Accepted file types: ${dedupedExtensions.join(", ")}.`;
  const maxFileSizeText = `Max file size: ${bytesToFileSize(maxSize)}.`;

  return (
    <VStack spacing={4} width="100%" align="stretch">
      <Flex
        {...getRootProps()}
        p={5}
        border="2px dashed"
        borderRadius="md"
        justify="center"
        align="center"
        direction="column"
        cursor="pointer"
        transition="all 0.2s"
        borderColor={
          isDragActive ? colors.fdy_purple[200] : colors.fdy_gray[200]
        }
        bg={isDragActive ? colors.fdy_purple[100] : colors.fdy_gray[100]}
        _hover={{
          borderColor: colors.fdy_purple[200],
          bg: colors.fdy_purple[100],
        }}
        minHeight="150px"
      >
        <input
          {...getInputProps({
            // Have to manually check the files array otherwise required prop
            // doesn't work as expected.
            required: required && files.length === 0,
            id: controlContext?.id,
          })}
        />
        <Upload
          size={24}
          fill={isDragActive ? colors.fdy_purple[500] : colors.fdy_gray[800]}
        />
        <Text fontWeight="medium">
          {isDragActive
            ? "Drop the files here..."
            : "Drag and drop files here, or click to select files"}
        </Text>
        <Text fontSize="sm" color={textColor} mt={2}>
          {fileTypesText}
        </Text>
        <Text fontSize="sm" color={textColor} mt={2}>
          {maxFileSizeText}
        </Text>
        <Text fontSize="sm" color={textColor} mt={2}>
          Max 50 columns per CSV recommended
        </Text>
        {files.length > 0 && (
          <Text fontSize="sm" mt={2}>
            {files.length} of {maxFiles} files selected
          </Text>
        )}
      </Flex>

      {files.length > 0 && (
        <Grid gap={2}>
          {files.map((fileWithStatus, index) => (
            <HStack
              key={index}
              p={2}
              borderWidth="1px"
              borderRadius="md"
              position="relative"
              spacing={4}
              align="center"
              bg={
                fileWithStatus.status === FileUploadState.error
                  ? colors.fdy_red[200]
                  : undefined
              }
              borderColor={
                fileWithStatus.status === FileUploadState.error
                  ? colors.fdy_red[200]
                  : undefined
              }
            >
              <FileStatusIcon file={fileWithStatus} />

              <VStack align="start" spacing={0} flex="1">
                <Text
                  fontSize="xs"
                  noOfLines={1}
                  title={fileWithStatus.file.name}
                >
                  {fileWithStatus.file.name}
                </Text>
                <Text fontSize="xs" color={textColor}>
                  {bytesToFileSize(fileWithStatus.file.size)}
                  {fileWithStatus.status === FileUploadState.error &&
                    fileWithStatus.errorMessage && (
                      <Text color={colors.fdy_red[500]} mt={1}>
                        {fileWithStatus.errorMessage}
                      </Text>
                    )}
                </Text>
              </VStack>

              <CloseButton
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFile(fileWithStatus.file.name);
                }}
                isDisabled={
                  fileWithStatus.status === FileUploadState.uploading ||
                  fileWithStatus.status === FileUploadState.done
                }
              />
            </HStack>
          ))}
        </Grid>
      )}
    </VStack>
  );
};
