import { CardStack } from "../../../ui/CardStack";
import {
  DatasetShowPageQuery_dataset,
  DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv,
} from "../../__generated__/DatasetShowPageQuery";
import { DatasetSampleCard } from "./DatasetSampleCard";
import { DatasetsDataCsvFilesTable as DatasetsDataFilesCard } from "./DatasetsDataCsvFilesTable";
import { DatasetsFilesUploadCard as DatasetsFileUploadCard } from "./DatasetsFilesUploadCard";

export function DatasetDataTabHosted({
  dataset,
  datasetOptions,
}: {
  dataset: DatasetShowPageQuery_dataset;
  datasetOptions: DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv;
}) {
  return (
    <CardStack>
      <DatasetsFileUploadCard options={datasetOptions} />
      <DatasetsDataFilesCard options={datasetOptions} />
      <DatasetSampleCard dataset={dataset} />
    </CardStack>
  );
}
