import { useDisclosure } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { useMemo } from "react";

import { useConfigurablePersonaSetAnalysisTraitNames } from "../../../hooks/useConfigurablePersonaSetAnalysisTraitNames";
import { Trait, useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Blankslate } from "../../ui/Blankslate";
import { Button } from "../../ui/Button";
import { TraitSelectorModal } from "../../ui/TraitSelectorModal";
import { PersonaSet } from "../personaSetFragment";
import { PersonaSetAnalysisGridSection } from "./PersonaSetAnalysisGrid";
import { PersonaSetAnalysisHeader } from "./PersonaSetAnalysisHeader";
import { PersonaSetAnalysisSizes } from "./PersonaSetAnalysisSizes";
import {
  PersonaSetDimensionsAccordion,
  PersonaSetDimensionsAccordionItem,
} from "./PersonaSetDimensionsAccordion";
import { AnalysisDimensionRow, DimensionTrait } from "./types";
import { usePersonaSetAnalysis } from "./usePersonaSetAnalysis";
import { sortPersonas } from "./utils";

/**
 * Render the various sections for the persona set analysis page.
 *
 * - Size: individual counts and % of total set
 * - Clustering traits: breakdown of traits used to cluster the persona set
 * - Traits (non clustering): breakdown of all other generic traits
 */
export function PersonaSetAnalysisSections({
  personaSet,
}: {
  personaSet: PersonaSet;
}) {
  const { id, personas, modelingFields } = personaSet;

  // sort personas consistently
  const sortedPersonas = sortPersonas(personas);

  // set up state for the trait selector modal
  const addTraitModalProps = useDisclosure();

  // Query the traits endpoint so we can get other trait meta info
  const { traits, loadingTraits, traitsMap } = useTraitsQuery();

  // Get the list of trait/field names to show in non clustering traits section.
  // This hook also methods to update the list of fields after the user customizes them.
  const {
    rosterAnalysisTraitNames,
    addAnalysisTrait,
    removeAnalysisTrait,
    loading: loadingFieldNames,
    updating,
  } = useConfigurablePersonaSetAnalysisTraitNames({
    rosterId: personaSet.id,
    onUpdateComplete() {
      // Close the modal after updating
      addTraitModalProps.onClose();
    },
  });

  // Generate the data shapes that are easily consumable by analysis UI
  const { clusteringDimensions, traitDimensions, loading } =
    usePersonaSetAnalysis({
      personaSetId: id,
      modelingFields,
      traitsMap,
    });

  // generate lists of shown 'other traits' and a list of traits we can let user to add
  const { picklist, rows } = useMemo(() => {
    const picklist: Trait[] = [];
    const rows: AnalysisDimensionRow[] = [];

    // loop through just 'other traits' since all clustering traits should be shown by default
    for (const row of traitDimensions) {
      // if user configured persona set to show the trait, add it to the rows
      if (rosterAnalysisTraitNames.includes(row.trait.name)) {
        rows.push(row);
      } else {
        // otherwise add it to the picklist
        const trait: Trait | undefined = traitsMap[row.trait.name];

        if (!trait) {
          console.warn(`Could not find trait for name: ${row.trait.name}`);
          continue;
        }

        picklist.push(trait);
      }
    }

    return {
      picklist,
      rows,
    };
  }, [traits, rosterAnalysisTraitNames, traitDimensions]);

  function handleTraitSelectorAdd(trait: Trait) {
    addAnalysisTrait(trait);
  }

  function handleTraitSelectorRemove(trait: DimensionTrait) {
    removeAnalysisTrait(trait);
  }

  if (loading || loadingTraits || loadingFieldNames) {
    return <AnimatedZapLogo />;
  }

  return (
    <div>
      <PersonaSetAnalysisHeader
        personas={sortedPersonas}
        personaSet={personaSet}
      />
      <PersonaSetAnalysisSizes personas={sortedPersonas} />

      <PersonaSetAnalysisGridSection label="Clustering traits">
        <PersonaSetDimensionsAccordion>
          {clusteringDimensions.map((props) => (
            <PersonaSetDimensionsAccordionItem
              key={props.trait.name}
              {...props}
            />
          ))}
        </PersonaSetDimensionsAccordion>
      </PersonaSetAnalysisGridSection>

      <PersonaSetAnalysisGridSection
        label="Traits"
        rightContent={
          <Button
            leftIcon={<Plus weight="bold" />}
            onClick={addTraitModalProps.onOpen}
            size="sm"
            variant="secondary"
            analyticsName="add-analysis-trait"
          >
            Add trait
          </Button>
        }
      >
        {rows.length > 0 ? (
          <PersonaSetDimensionsAccordion>
            {rows.map((props) => (
              <PersonaSetDimensionsAccordionItem
                key={props.trait.name}
                onRemoveClick={handleTraitSelectorRemove}
                {...props}
              />
            ))}
          </PersonaSetDimensionsAccordion>
        ) : (
          <Blankslate
            title="No traits added yet"
            text="You can analyze additional traits for this persona set by clicking the add trait button above."
          />
        )}
      </PersonaSetAnalysisGridSection>

      {addTraitModalProps.isOpen ? (
        <TraitSelectorModal
          availableTraits={picklist}
          isOpen={addTraitModalProps.isOpen}
          onClose={addTraitModalProps.onClose}
          select={handleTraitSelectorAdd}
          adding={updating}
          autoClose={false}
        />
      ) : null}
    </div>
  );
}
