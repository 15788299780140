import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import { memo, ReactNode } from "react";

import { number, percent } from "../../../utils/formatters";
import { Truncate } from "../../ui/Truncate";
import { PercentBarChart } from "./PercentBarChart";
import {
  PersonaGridRowCell,
  PersonaGridRowDivider,
  PersonaGridRowHeader,
  PersonaSetAnalysisGridRow,
} from "./PersonaSetAnalysisGrid";
import {
  AnalysisDimensionRow,
  AnalysisTraitPersona,
  DimensionTrait,
} from "./types";

export function PersonaSetDimensionsAccordion({
  children,
}: {
  children: ReactNode;
}) {
  return <Accordion allowMultiple>{children}</Accordion>;
}

/**
 * Render a percentage chart for an analysis dimension bin.
 * Sets up event listener for tooltips since we want to customize what elements trigger them.
 */
function PersonaSetDimensionBin({
  height,
  bin,
}: {
  height: number;
  bin: AnalysisTraitPersona["bins"][number];
}) {
  const tip = useDisclosure();

  const tooltip = (
    <span style={{ fontWeight: "normal" }}>
      <strong>{bin.label}</strong> {number(bin.count)} ({percent(bin.percent)})
    </span>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height,
      }}
      onMouseEnter={tip.onOpen}
      onMouseLeave={tip.onClose}
    >
      <PercentBarChart
        percent={bin.percent}
        tooltip={tooltip}
        isTooltipOpen={tip.isOpen}
      />
    </Box>
  );
}

function RemoveTraitButton({
  trait,
  onRemoveClick,
}: {
  trait: DimensionTrait;
  onRemoveClick: (trait: DimensionTrait) => void;
}) {
  const label = `Remove "${trait.literate}" trait`;
  // position the remove button floating on top of the whole accordion button
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: "50%",
        width: "100%",
        transform: "translateY(-50%)",
        zIndex: 4,
        pointerEvents: "none",
      }}
    >
      <Container sx={{ textAlign: "right" }}>
        <Tooltip label={label} hasArrow placement="top">
          <IconButton
            variant="icon"
            size="xs"
            icon={<Trash />}
            aria-label={label}
            onClick={() => onRemoveClick(trait)}
            sx={{
              pointerEvents: "all",
            }}
          />
        </Tooltip>
      </Container>
    </Box>
  );
}

function PersonaSetDimensionsAccordionButton({
  trait,
  columns,
  personas,
  onRemoveClick,
}: {
  trait: DimensionTrait;
  columns: number;
  personas: AnalysisDimensionRow["personas"];
  onRemoveClick?: (trait: DimensionTrait) => void;
}) {
  return (
    <Box sx={{ position: "relative" }}>
      {onRemoveClick ? (
        <RemoveTraitButton trait={trait} onRemoveClick={onRemoveClick} />
      ) : null}

      <AccordionButton
        sx={{
          display: "block",
          textAlign: "left",
          p: 0,
        }}
      >
        <PersonaSetAnalysisGridRow columns={columns}>
          <Box sx={{ display: "flex" }}>
            <AccordionIcon />
            <PersonaGridRowHeader title={trait.literate ?? trait.name} />
          </Box>

          {personas.map((p) => (
            <PersonaGridRowCell key={p.id}>
              {/* hide summary otherwise it's counted as part of the button label.
            That's not really suitable until/unless we revise the whole grid so
            there's proper headers and such. */}
              <span aria-hidden>{p.common}</span>
            </PersonaGridRowCell>
          ))}
        </PersonaSetAnalysisGridRow>
      </AccordionButton>
    </Box>
  );
}

function PersonaSetDimensionsAccordionPanel({
  binHeight,
  columns,
  personas,
}: {
  binHeight: number;
  columns: number;
  personas: AnalysisDimensionRow["personas"];
}) {
  const headers = personas[0].bins.map((bin) => bin.header);

  return (
    <AccordionPanel sx={{ p: 0 }}>
      <PersonaSetAnalysisGridRow columns={columns}>
        <Box>
          {headers.map((header) => (
            <Box
              key={header}
              sx={{
                display: "flex",
                alignItems: "center",
                height: binHeight,
                fontSize: "fdy_xs",
                lineHeight: 1,
                pl: 8,
                maxWidth: 200,
              }}
            >
              <Truncate title={header}>{header}</Truncate>
            </Box>
          ))}
        </Box>

        {personas.map((p) => (
          <Box key={p.id}>
            {p.bins.map((bin, idx) => {
              return (
                <PersonaSetDimensionBin
                  key={p.id + idx}
                  height={binHeight}
                  bin={bin}
                />
              );
            })}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "fdy_xs",
              }}
            >
              <span>0</span>
              <span>100%</span>
            </Box>
          </Box>
        ))}
      </PersonaSetAnalysisGridRow>
    </AccordionPanel>
  );
}

export const PersonaSetDimensionsAccordionItem = memo(
  function PersonaSetDimensionsAccordionItem({
    trait,
    personas,
    onRemoveClick,
  }: AnalysisDimensionRow & {
    onRemoveClick?: (trait: DimensionTrait) => void;
  }) {
    // this is chakra unit so N*4px
    const binHeight = 6;

    // label/headers column + amount of personas
    const columnCount = personas.length + 1;

    return (
      <AccordionItem as={PersonaGridRowDivider} sx={{ m: 0, border: 0 }}>
        {({ isExpanded }) => (
          <>
            <PersonaSetDimensionsAccordionButton
              trait={trait}
              personas={personas}
              columns={columnCount}
              onRemoveClick={onRemoveClick}
            />
            {/* NOTE: It's important we don't render the panel content until it's opened.
              It helps a lot with rendering performance. */}
            {isExpanded ? (
              <PersonaSetDimensionsAccordionPanel
                binHeight={binHeight}
                columns={columnCount}
                personas={personas}
              />
            ) : null}
          </>
        )}
      </AccordionItem>
    );
  }
);
