import { Box, Heading, Text, Tooltip } from "@chakra-ui/react";
import { Info } from "@phosphor-icons/react";
import React, { PropsWithChildren } from "react";

import { colors } from "../../styles/chakra-theme-v2";
import { IconWithText } from "./IconWithText";

export const SummaryItem = ({
  title,
  children,
  tooltipValue,
}: {
  title: string;
  children: React.ReactNode;
  tooltipValue?: string;
}) => {
  const content = (
    <Box
      sx={{
        flex: 1,
        padding: 4,
        borderRadius: "md",
        border: "1px solid",
        borderColor: colors.fdy_gray[200],
      }}
    >
      <Heading as="h3" sx={{ fontSize: "fdy_md", fontWeight: "normal" }}>
        {tooltipValue ? (
          <IconWithText>
            {title}
            <Info color={colors.fdy_gray[500]} weight="fill" />
          </IconWithText>
        ) : (
          title
        )}
      </Heading>
      <Text fontSize="fdy_xl" fontWeight="semibold">
        {children}
      </Text>
    </Box>
  );

  return tooltipValue ? (
    <Tooltip label={tooltipValue} hasArrow placement="top">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export const SummarySublabel = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Text
      as="span"
      sx={{
        display: "block",
        fontSize: "fdy_sm",
        color: colors.fdy_gray[800],
        fontWeight: "normal",
      }}
    >
      {children}
    </Text>
  );
};

export const SummaryGroup: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 4,
      }}
    >
      {children}
    </Box>
  );
};
