import { List, ListItem } from "@chakra-ui/react";
import { OutputToTraitsMergePatch } from "@fdy/faraday-js";
import { Plus } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useState } from "react";

import { ActionableCardItem } from "../../../../ui/ActionableCardItem";
import { Button } from "../../../../ui/Button";
import { CardV2 } from "../../../../ui/Card/CardV2";
import { DatasetColumn } from "../shared/DatasetColumn";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { DatasetsTraitsModal } from "./DatasetsTraitsModal";

// faraday-js package types aren't quite right. Merge patch objects should have nullable values so they can be removed.
type NullableRecord<T> = { [K in keyof T]: T[K] | null };
export type OutputToTraitsState = NullableRecord<OutputToTraitsMergePatch>;

type OutputTrait = {
  name: string;
  column_name: string;
};

/**
 * Renders a card for managing traits on a dataset.
 * Users can add, edit, and delete traits.
 */
export function DatasetsTraitsCard({
  traits,
  setTraits,
  detectedColumns,
  managedDataset,
  sampleData,
}: {
  traits: OutputToTraitsState;
  setTraits: Dispatch<SetStateAction<OutputToTraitsState>>;
  detectedColumns: DetectedColumn[];
  managedDataset: boolean;
  sampleData: SampleData | undefined;
}) {
  const [editingTrait, setEditTrait] = useState<OutputTrait>();

  function handleAddTrait() {
    setEditTrait({
      name: "",
      column_name: "",
    });
  }

  function handleFinish(trait: OutputTrait) {
    setTraits((state) => {
      const nextState = {
        ...state,
        [trait.name]: { column_name: trait.column_name },
      };
      // renaming a trait removes the previous entry
      if (editingTrait?.name && editingTrait.name !== trait.name) {
        nextState[editingTrait.name] = null;
      }

      return nextState;
    });

    setEditTrait(undefined);
  }

  const handleEditTrait = (trait: OutputTrait) => {
    setEditTrait(trait);
  };

  const handleTraitDeletion = (trait: OutputTrait) => {
    setTraits({
      ...traits,
      [trait.name]: null,
    });
  };

  const handleModalClose = () => {
    setEditTrait(undefined);
  };

  const allTraits: OutputTrait[] = Object.entries(traits)
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([name, trait]) => {
      // trait can be null, which implies it's to be deleted on next merge patch
      if (!trait?.column_name) return;
      return {
        name,
        column_name: trait.column_name,
      };
    })
    .filter((trait): trait is OutputTrait => !!trait);

  return (
    <CardV2
      title="Traits"
      suffix="Less common"
      text="Traits are interesting data points that are considered fixed over time
      - they are characteristics about people, not tied to your event data.
      They can enhance the usefulness of your data in Faraday, for example,
      whether a person owns or rents, hobbies, income, first product
      purchase category. These traits can be used to create cohorts, used
      for analysis, etc."
    >
      <List>
        {allTraits.map((trait) => {
          if (!trait) return null;

          return (
            <ListItem key={trait.name} mb={4}>
              <ActionableCardItem
                title={trait.name}
                content={<DatasetColumn columnName={trait.column_name} />}
                onEdit={() => handleEditTrait(trait)}
                onDelete={() => handleTraitDeletion(trait)}
                menuBtnLabel={`${trait.name} options`}
              />
            </ListItem>
          );
        })}
      </List>

      {!managedDataset && (
        <Button
          width="100%"
          variant="secondary"
          leftIcon={<Plus weight="bold" />}
          onClick={handleAddTrait}
          analyticsName="add-trait"
        >
          Add trait
        </Button>
      )}

      {editingTrait && (
        <DatasetsTraitsModal
          initialState={editingTrait}
          detectedColumns={detectedColumns}
          sampleData={sampleData}
          managedDataset={managedDataset}
          onFinish={handleFinish}
          onClose={handleModalClose}
          onCancel={handleModalClose}
        />
      )}
    </CardV2>
  );
}
