import {
  Alert,
  AlertDescription,
  AlertStatus,
  AlertTitle,
  Box,
  CloseButton,
  Spinner,
  ToastProps,
  ToastProviderProps,
  useMultiStyleConfig,
  useToast as useChakraToast,
} from "@chakra-ui/react";
import {
  CheckCircle,
  DotsThree,
  Icon,
  Info,
  Warning,
  XCircle,
} from "@phosphor-icons/react";

export type { ToastProps };

const toastIcons: Record<AlertStatus, Icon> = {
  success: CheckCircle,
  error: XCircle,
  warning: Warning,
  info: Info,
  loading: DotsThree,
};

// Custom toast alert component.
// Based off https://github.com/chakra-ui/chakra-ui/blob/4449b7fdd5de8bab1cb690b7d0f86bd71f6e4650/packages/toast/src/toast.tsx#L23
export function CustomToastSlice({
  title,
  description,
  status = "info",
  onClose,
  id,
}: ToastProps) {
  const alertTitleId = id !== undefined ? `toast-${id}-title` : undefined;

  // We want to render our custom icon, but chakra v1 doesn't support children as a prop
  // for AlertIcon (chakra v2 does).
  // In mean time, manually get the variant color for our custom icon.
  // https://v1.chakra-ui.com/docs/styled-system/theming/component-style#styling-multipart-components
  // TODO: some day upgrade to react 18 to enable us to upgrade to chakra v2
  // https://chakra-ui.com/guides/migration#1-update-your-dependencies
  const styles = useMultiStyleConfig("Alert", { variant: status });

  const Icon = toastIcons[status];

  return (
    <Alert id={String(id)} variant={status} aria-labelledby={alertTitleId}>
      <Box __css={styles.icon}>
        {status === "loading" ? (
          <Spinner />
        ) : (
          <Icon size={24} weight="duotone" />
        )}
      </Box>
      <Box>
        <AlertTitle id={alertTitleId}>{title}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
      </Box>
      <CloseButton onClick={onClose} ml="auto" />
    </Alert>
  );
}

export const fdyDefaultToastOptions: ToastProviderProps = {
  defaultOptions: {
    position: "bottom",
    isClosable: true,
    duration: 5000,
    containerStyle: {
      width: 748,
      maxWidth: "100%",
    },
    render: ({ id, onClose, ...props }) => (
      <CustomToastSlice id={id} onClose={onClose} {...props} />
    ),
  },
};

/**
 * Wrapper hook for Chakra's useToast so we can control styles more and set our preferred toast defaults.
 * https://chakra-ui.com/docs/components/feedback/toast
 */
export function useToast() {
  return useChakraToast();
}
