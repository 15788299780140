import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";

import { FEATURES_SHOWN_LIMIT } from "../../../../hooks/useProgressiveDisclosureItems";
import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import {
  OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures,
  OutcomeAnalysisQuery_outcomeAnalysis_tenureFeatures,
} from "../__generated__/OutcomeAnalysisQuery";
import {
  OutcomeAnalysisRecognitionGroupSelect,
  useRecognitionGroupState,
} from "../OutcomeAnalysisRecognitionGroupSelect";
import { outcomeTenureLabel } from "../outcomeTenureLabel";
import {
  OutcomeDataCardFeatureAccordion,
  OutcomeDataCardFeatureAccordionProps,
} from "./OutcomeDataCardFeatureAccordion";

/**
 * Renders the feature importances and directionalities for an outcome.
 */
export function OutcomeDataCardFeatures({
  overallFeatures,
  tenureFeatures,
  eligibleCohortName,
  outcomeName,
}: {
  eligibleCohortName: string | null;
  overallFeatures: OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures;
  tenureFeatures:
    | OutcomeAnalysisQuery_outcomeAnalysis_tenureFeatures
    | null
    | undefined;
  outcomeName: string;
}) {
  const { selectedGroup, availableGroups, setSelectedGroup } =
    useRecognitionGroupState(overallFeatures);

  // manage shown state here so it's not reset when switching tabs
  const [shown, setShown] = useState(FEATURES_SHOWN_LIMIT);

  const overallFeaturesGroup = overallFeatures[selectedGroup];
  const tenureFeaturesGroup = tenureFeatures?.[selectedGroup];

  const accordionProps: Omit<OutcomeDataCardFeatureAccordionProps, "features"> =
    {
      outcomeName,
      eligibleCohortName,
      shown,
      onShowMore: setShown,
    };

  const overallTab = (
    <OutcomeDataCardFeatureAccordion
      features={overallFeaturesGroup}
      {...accordionProps}
    />
  );

  const select = (
    <OutcomeAnalysisRecognitionGroupSelect
      groups={availableGroups}
      value={selectedGroup}
      onChange={setSelectedGroup}
    />
  );

  // Render non-dynamic outcomes as a single accordion, no tabs
  if (tenureFeaturesGroup && tenureFeaturesGroup.length > 1) {
    const feats = assertArrayAndUnwrap(tenureFeaturesGroup);

    const tabs = [
      {
        label: "Overview",
        panel: overallTab,
      },
      ...feats.map((tenureFeature) => {
        const label = outcomeTenureLabel(tenureFeature);
        return {
          label,
          panel: (
            <OutcomeDataCardFeatureAccordion
              features={tenureFeature.features}
              {...accordionProps}
            />
          ),
        };
      }),
    ];

    return (
      <>
        {select}
        <Tabs size="sm">
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.label}>{tab.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.label}>{tab.panel}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </>
    );
  }

  if (overallFeaturesGroup.length) {
    return (
      <>
        {select}
        {overallTab}
      </>
    );
  }
  return null;
}
