import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { UnreachableCodeError } from "@fdy/jwt";
import { useMemo } from "react";
import * as React from "react";

import {
  TargetAggregateGeographic,
  TargetAggregateIdentified,
} from "../../../../__generated__/sojournerGlobalTypes";
import { AccountConfigMap } from "../../../../hooks/accountConfigHooks";
import { objectEntries } from "../../../../utils/objectEntries";
import { DatasetColumnSelect } from "../../../datasets/DatasetsShowPage/DatasetForm/events/DatasetColumnSelect";
import { isSampleData } from "../../../datasets/DatasetsShowPage/DatasetForm/shared/OptionWithSampleData";
import { DetectedColumn } from "../../../datasets/DatasetsShowPage/DatasetForm/shared/types";
import { Checkbox } from "../../../ui/Checkbox";
import { RadioGroupV2, RadioGroupV2Props } from "../../../ui/RadioGroupV2";
import { ConnectionTypeInfo, isConnectionType } from "../../connectionUtils";
import { TargetFragment_representation } from "../__generated__/TargetFragment";
import { ScopeDataset } from "../useScopeDependencies";

export type TargetRepresentationType = TargetFragment_representation;

type RepresentationTypename = TargetRepresentationType["__typename"];

export enum TargetMode {
  aggregated = "aggregated",
  hashed = "hashed",
  identified = "identified",
  referenced = "referenced",
}

// The sojourner API expects representation mode as enums, but our gql layer doesn't produce enums
// for those modes and gql mutations (post/patch) expect a string - not great.
// A rough equivalent is matching the typename to the enum value but as a string, so do that here.
// If our gql types change, this should fail TS validation, which would help catch api breaking changes.
// If you change the string values, you'll need to verify they pass API validation/match the OAS spec.
export const REPRESENTATION_MODE_MAP: Record<
  RepresentationTypename,
  keyof typeof TargetMode
> = {
  TargetModesAggregated: "aggregated",
  TargetModesHashed: "hashed",
  TargetModesIdentified: "identified",
  TargetModesReferenced: "referenced",
};

export const geographicAggregateLabels: Record<
  TargetAggregateGeographic,
  string
> = {
  [TargetAggregateGeographic.CARRIER_ROUTE]: "Carrier route",
  [TargetAggregateGeographic.CENSUS_BLOCK_GROUP]: "Census block group",
  [TargetAggregateGeographic.CENSUS_TRACT]: "Census tract",
  [TargetAggregateGeographic.COUNTY]: "County",
  [TargetAggregateGeographic.DMA]: "DMA",
  [TargetAggregateGeographic.METRO]: "Metro",
  [TargetAggregateGeographic.POSTCODE]: "Zipcode",
  [TargetAggregateGeographic.STATE]: "State",
};

const GEOGRAPHIC_OPTIONS: {
  value: TargetAggregateGeographic;
  label: string;
}[] = objectEntries(geographicAggregateLabels).map(([value, label]) => ({
  value,
  label,
}));

export const identifiedAggregateLabels: Record<
  TargetAggregateIdentified,
  string
> = {
  [TargetAggregateIdentified.PERSON]: "One row per person",
  [TargetAggregateIdentified.RESIDENCE]: "One row per residence (address)",
};

export const IDENTIFIED_OPTIONS: {
  value: TargetAggregateIdentified;
  label: string;
}[] = objectEntries(identifiedAggregateLabels).map(([value, label]) => ({
  value,
  label,
}));

interface RepresentationRadiosExtraData {
  datasets: ScopeDataset[];
  representation: TargetRepresentationType;
  setGeographicAggregate: (aggregate: TargetAggregateGeographic) => void;
  setIdentifiedAggregate: (aggregate: TargetAggregateIdentified) => void;
  setReference: (datasetId: string, columnName: string) => void;
  setIncludeGeometry: (includeGeometry: boolean) => void;
  connectionTypeInfo: ConnectionTypeInfo;
}

const REPRESENTATION_OPTIONS: RadioGroupV2Props<
  RepresentationTypename,
  RepresentationRadiosExtraData
>["options"] = [
  {
    label: "Hashed",
    value: "TargetModesHashed",
    sublabel: "Best for deploying audiences to ad platforms",
    helpText: (
      <>
        May produce multiple rows per person — one for each hashed identity
        Faraday associates with that person — including people you have not
        previously identified in a dataset.{" "}
        <strong>
          Precise score columns will be excluded from the result to protect
          privacy.
        </strong>
      </>
    ),
  },
  {
    label: "Referenced",
    value: "TargetModesReferenced",
    sublabel: "Best for merging data back into your stack",
    helpText: (
      <>
        Will produce one row per person, limited to people you have already
        identified in a dataset of your choice. To protect privacy, this will{" "}
        <strong>not include identifying information</strong> other than the key
        you select.
      </>
    ),
    renderAfter({ selected, data }) {
      if (!selected) return null;

      const datasetRep = data.representation;

      if (datasetRep.__typename !== "TargetModesReferenced") {
        throw new Error("Expected TargetModesReferenced");
      }

      function handleChangeDataset(
        event: React.ChangeEvent<HTMLSelectElement>
      ) {
        data.setReference(event.target.value, "");
      }

      const currentDataset = data.datasets.find(
        (d) =>
          d.id === datasetRep.reference.datasetId ||
          d.id === datasetRep.referenceDatasetId
      );

      function handleChangeColumn(value: string | null) {
        if (!currentDataset?.id) throw new Error("No dataset selected");
        data.setReference(currentDataset.id, value ?? "");
      }

      return (
        <>
          <FormControl mb={4}>
            <FormLabel>Dataset</FormLabel>
            <Select
              value={
                datasetRep.reference.datasetId ?? datasetRep.referenceDatasetId
              }
              onChange={handleChangeDataset}
              placeholder="Select a dataset"
              required
            >
              {[...data.datasets]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((opt) => {
                  return (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl
            // this will only happen if the detected columns job failed
            // it is unlikely for the user to get this far with failed detected columns
            // unless they are a dev working on popsicle locally, since the babysitter does not run locally
            isInvalid={
              currentDataset && !currentDataset?.detectedColumns.length
            }
          >
            <FormLabel>Column in dataset</FormLabel>
            <DatasetColumnSelect
              detectedColumns={
                (currentDataset?.detectedColumns as DetectedColumn[]) ?? []
              }
              value={datasetRep.reference.columnName}
              onChange={handleChangeColumn}
              sampleData={
                currentDataset?.sample && isSampleData(currentDataset.sample)
                  ? currentDataset?.sample
                  : undefined
              }
              analyticsName="dataset_column_select"
              required={!!currentDataset}
              disabled={!currentDataset}
            />

            <FormErrorMessage>
              Faraday did not detect any columns in this dataset (or it is still
              being built). If this error persists, please contact support.
            </FormErrorMessage>
          </FormControl>
        </>
      );
    },
  },
  {
    label: "Identified",
    value: "TargetModesIdentified",
    sublabel: "Best for direct mail and canvassing campaigns",
    helpText: (
      <>
        Will produce one row per person or one row per residence (address),
        including those you have not previously identified in a dataset. The
        name and address information comes from Faraday's national dataset
        (FIG).{" "}
        {/*
          FIXME: 10k is default plan setting but accounts could differ.
          Maybe we need to have an /account API endpoint to pull this plan setting?
        */}
        <strong>
          This option will only include up to 10k people (or your plan limit)
          previously unknown to you.
        </strong>
      </>
    ),
    renderAfter({ selected, data }) {
      if (!selected) return null;

      if (data.representation.__typename !== "TargetModesIdentified") {
        throw new Error("Expected TargetModesIdentified");
      }

      function onChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        data.setIdentifiedAggregate(
          event.target.value as TargetAggregateIdentified
        );
      }

      return (
        <FormControl>
          <FormLabel>Individual identification options</FormLabel>
          <Select
            value={data.representation.identifiedAggregate}
            onChange={onChange}
            // should be fine as long as default option is 'person'
            disabled={isConnectionType(data.connectionTypeInfo, "lookup_api")}
          >
            {IDENTIFIED_OPTIONS.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    },
  },
  {
    label: "Aggregated",
    value: "TargetModesAggregated",
    sublabel: "Best for geotargeted ad campaigns",
    helpText: (
      <>
        Will produce one row per geographical area.{" "}
        <strong>
          All payload elements (outcomes, personas, cohorts) are represented in
          unique columns and aggregated based on your chosen geographical area.
        </strong>
      </>
    ),
    renderAfter({ selected, data }) {
      if (!selected) return null;

      if (data.representation.__typename !== "TargetModesAggregated") {
        throw new Error("Expected TargetModesAggregated");
      }

      function handleGeographicAggregate(
        event: React.ChangeEvent<HTMLSelectElement>
      ): void {
        data.setGeographicAggregate(
          event.target.value as TargetAggregateGeographic
        );
      }

      function handleIncludeGeometry(
        event: React.ChangeEvent<HTMLInputElement>
      ): void {
        data.setIncludeGeometry(event.target.checked);
      }

      return (
        <FormControl>
          <FormLabel>Organize rows by geographic type</FormLabel>
          <Select
            value={data.representation.geographicAggregate}
            onChange={handleGeographicAggregate}
            mb={4}
          >
            {GEOGRAPHIC_OPTIONS.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
          <Checkbox
            onChange={handleIncludeGeometry}
            isChecked={data.representation.includeGeometry ?? false}
            isDisabled={data.connectionTypeInfo.slug === "lookup_api"}
            analyticsName="includes-geometry"
          >
            <span>
              <strong>Include geometry</strong> - Add the geographic area in the
              target output.
            </span>
          </Checkbox>
        </FormControl>
      );
    },
  },
];

interface TargetRepresentationProps {
  representation: TargetRepresentationType;
  onChange: (representation: TargetRepresentationType) => void;
  lockedTypename?: RepresentationTypename;
  datasets: ScopeDataset[];
  accountConfigMap: AccountConfigMap;
  connectionTypeInfo: ConnectionTypeInfo;
}

/**
 * Renders a list to radio buttons to select what the scope output
 * (i.e. target representation) should look like when deployed.
 *
 * Some radio buttons, once selected, show additional inputs/selects below them.
 */
export function TargetRepresentationRadios({
  representation,
  onChange,
  lockedTypename,
  datasets,
  accountConfigMap,
  connectionTypeInfo,
}: TargetRepresentationProps) {
  const enabledOptions = useMemo(
    () =>
      REPRESENTATION_OPTIONS.map((opt) => {
        opt.disabled = false;
        // when there are no datasets for the scope, it's not possible to use the "referenced" mode
        if (opt.value === "TargetModesReferenced" && datasets.length === 0) {
          opt.disabled = true;
        }

        // lookup only allows mode=identified+aggregate=person or mode=aggregate+aggregate=<any>
        if (
          isConnectionType(connectionTypeInfo, "lookup_api") &&
          (opt.value === "TargetModesHashed" ||
            opt.value === "TargetModesReferenced")
        ) {
          opt.disabled = true;
        }

        if (
          opt.value === "TargetModesIdentified" &&
          accountConfigMap["targets.identified_allowed"] !== true
        ) {
          opt.disabled = true;
        }
        // disable other modes if we have a locked type
        if (lockedTypename && opt.value !== lockedTypename) {
          opt.disabled = true;
        }
        return opt;
      }),
    [datasets, lockedTypename]
  );

  function setReference(id: string, columnName: string) {
    onChange({
      __typename: "TargetModesReferenced",
      mode: "referenced",
      referencedPreset: null,
      reference: {
        __typename: "TargetReferencedReference",
        datasetId: id,
        columnName,
      },
      referenceDatasetId: id,
    });
  }

  function setGeographicAggregate(aggregate: TargetAggregateGeographic) {
    if (representation.__typename !== "TargetModesAggregated") {
      throw new Error("Expected TargetModesAggregated");
    }
    onChange({
      __typename: "TargetModesAggregated",
      mode: "aggregated",
      aggregatedPreset: null,
      geographicAggregate: aggregate,
      includeGeometry: representation.includeGeometry,
    });
  }
  function setIncludeGeometry(includeGeometry: boolean) {
    if (representation.__typename !== "TargetModesAggregated") {
      throw new Error("Expected TargetModesAggregated");
    }
    onChange({
      __typename: "TargetModesAggregated",
      mode: "aggregated",
      aggregatedPreset: null,
      geographicAggregate: representation.geographicAggregate,
      includeGeometry,
    });
  }

  function setIdentifiedAggregate(aggregate: TargetAggregateIdentified) {
    onChange({
      __typename: "TargetModesIdentified",
      mode: "identified",
      identifiedPreset: null,
      identifiedAggregate: aggregate,
    });
  }

  function handleModeChange(typename: RepresentationTypename) {
    const mode = REPRESENTATION_MODE_MAP[typename];
    if (typename === "TargetModesHashed") {
      onChange({
        __typename: "TargetModesHashed",
        mode,
        hashedPreset: null,
      });
    } else if (typename === "TargetModesReferenced") {
      onChange({
        __typename: "TargetModesReferenced",
        mode,
        referencedPreset: null,
        reference: {
          __typename: "TargetReferencedReference",
          datasetId: "",
          columnName: "",
        },
        referenceDatasetId: "",
      });
    } else if (typename === "TargetModesIdentified") {
      onChange({
        __typename: "TargetModesIdentified",
        mode,
        identifiedPreset: null,
        identifiedAggregate: TargetAggregateIdentified.PERSON,
      });
    } else if (typename === "TargetModesAggregated") {
      onChange({
        __typename: "TargetModesAggregated",
        mode,
        aggregatedPreset: null,
        geographicAggregate: TargetAggregateGeographic.COUNTY,
        includeGeometry: false,
      });
    } else {
      throw new UnreachableCodeError(typename);
    }
  }

  return (
    <RadioGroupV2<RepresentationTypename, RepresentationRadiosExtraData>
      name="representation"
      options={enabledOptions}
      value={representation.__typename}
      renderAfterData={{
        representation,
        datasets,
        setGeographicAggregate,
        setIdentifiedAggregate,
        setReference,
        setIncludeGeometry,
        connectionTypeInfo,
      }}
      onChange={handleModeChange}
      analyticsName="representation"
    />
  );
}
