import { useState } from "react";
import { useError } from "react-use";

import { useFdyClient } from "../../../services/FdyClientProvider";

export interface FileWithStatus {
  file: File;
  status?: FileUploadState;
  errorMessage?: string;
}

export enum FileUploadState {
  idle,
  uploading,
  done,
  error,
}

/**
 * A custom hook for managing and uploading files to a specified directory.
 *
 * @param uploadDirectory - The directory where the files will be uploaded.
 *                          The directory name will be sanitized to ensure it contains only valid characters.
 *
 * @returns An object containing:
 * - `uploadFiles`: A function to start the upload process for all files.
 * - `files`: The current list of files with their statuses and optional error messages.
 * - `setFiles`: A function to update the list of files.
 * - `someFilesUploading`: A boolean indicating if any file is currently being uploaded.
 *
 * @remarks
 * - Each file's name and the upload directory name are sanitized to replace invalid characters with underscores.
 * - The `uploadFiles` function updates the status of each file during the upload process:
 *   - `FileUploadState.uploading` while the file is being uploaded.
 *   - `FileUploadState.done` if the upload is successful.
 *   - `FileUploadState.error` if the upload fails, along with an error message.
 * - If an error occurs during the upload process, it is dispatched using the `useError` hook.
 * - After all files are uploaded, the file list is cleared.
 *
 * @throws Will rethrow any error encountered during the upload process after updating the file's status.
 */
export function useUploadableFiles(uploadDirectory: string) {
  const [files, setFiles] = useState<FileWithStatus[]>([]);
  const client = useFdyClient();
  const dispatchError = useError();

  const uploadFiles = async () => {
    try {
      for (const file of files) {
        // Generate a random nonce to avoid collisions when uploading the same file multiple times
        // Put it at the beginning of the filename to avoid messing up the extension
        const array = new Uint8Array(5);
        crypto.getRandomValues(array);
        const nonce = Array.from(array, (b) =>
          b.toString(36).padStart(2, "0")
        ).join("");
        const cleanFileName =
          nonce +
          "_" +
          file.file.name
            .replace(/([^-A-Za-z0-9_.]+)/gi, "_")
            .replace(/^-/, "_");

        const cleanDirName = uploadDirectory
          .replace(/([^-A-Za-z0-9_]+)/gi, "_")
          .replace(/^-/, "_");

        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file.name === file.file.name
              ? { ...f, status: FileUploadState.uploading }
              : f
          )
        );

        try {
          await client.uploads.createUpload(
            cleanDirName,
            cleanFileName,
            file.file
          );
          // Update file status to done
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file.name === file.file.name
                ? { ...f, status: FileUploadState.done }
                : f
            )
          );
        } catch (error) {
          // Update file status to error
          const errorMessage =
            error instanceof Error ? error.message : "Upload failed";

          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file.name === file.file.name
                ? { ...f, status: FileUploadState.error, errorMessage }
                : f
            )
          );

          throw error;
        }
      }

      // Clear files after upload
      setFiles([]);
    } catch (error) {
      if (error instanceof Error) {
        dispatchError(error);
      } else {
        throw error;
      }
    }
  };

  const someFilesUploading = files.some(
    (f) => f.status === FileUploadState.uploading
  );

  return { uploadFiles, files, setFiles, someFilesUploading };
}
