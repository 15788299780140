import { Flex, Tag, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

const TagListItem = ({
  children,
  ...props
}: {
  children: ReactNode;
} & React.ComponentProps<typeof Tag>) => {
  return (
    <Tag {...props}>
      <Text as="span" noOfLines={1}>
        {children}
      </Text>
    </Tag>
  );
};

function TagList({
  tags,
  children,
}: {
  tags?: ReactNode[];
  children?: ReactNode;
}) {
  return (
    <Flex as="ul" gap={2} flexWrap="wrap">
      {tags?.map((t, i) => (
        <TagListItem key={i}>{t}</TagListItem>
      ))}
      {children}
    </Flex>
  );
}

TagList.Item = TagListItem;

export { TagList };
