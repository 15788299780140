import { ToastProps, useToast } from "../../hooks/useToast";
import { SwitchV2 } from "../ui/Switch/SwitchV2";
import { useUpdateCohort } from "./useUpdateCohort";

export const cohortExploreToast: ToastProps = {
  status: "success",
  title: "Cohort enabled for Explore",
  description:
    "Cohort will be available in Explore soon. We will email you when it's ready.",
};

export const getCohortExploreSwitchLabel = (cohort: { name: string }) =>
  `Show ${cohort.name} in Explore`;

interface CohortExploreSwitchProps {
  cohort: {
    id: string;
    explore: boolean | null;
    name: string;
  };
}

/**
 * Render a switch to enable/disable a cohort for Explore
 */
export function CohortExploreSwitch({ cohort }: CohortExploreSwitchProps) {
  const toast = useToast();

  const { updateCohort, updating } = useUpdateCohort({
    onCompleted(data) {
      if (data.updateCohort?.explore) {
        toast(cohortExploreToast);
      }
    },
  });

  const checked = cohort.explore ?? false;

  function handleToggle() {
    updateCohort(cohort.id, {
      explore: !checked,
    });
  }

  return (
    <SwitchV2
      label={getCohortExploreSwitchLabel(cohort)}
      visuallyHideLabel
      isChecked={checked}
      onChange={handleToggle}
      isDisabled={updating}
    />
  );
}
