import { Box, Flex, Text } from "@chakra-ui/react";
import { UnreachableCodeError } from "@fdy/jwt";
import { keyBy } from "lodash";
import camelCase from "lodash/camelCase";
import * as React from "react";
import { ReactNode, useMemo } from "react";

import {
  Direction,
  TargetStructureTransformationInput,
} from "../../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { TraitsMap } from "../../../../hooks/useTraitsQuery";
import { conditionToSummary } from "../../../../utils/conditionToSummary";
import { titleCase } from "../../../../utils/formatters";
import { traitConditionToSummary } from "../../../cohorts/CohortForm/CohortTraitsCard/utils";
import { Connection } from "../../../connections/useConnectionsQuery";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../../ui/ConfigBreakdownTable";
import { ResourceLink } from "../../../ui/ResourceLink";
import { RouterLink } from "../../../ui/RouterLink";
import { VendorLogo } from "../../../ui/VendorLogo";
import {
  getConnectionTypeInfoFromTargetOptions,
  getTargetOptionsByConnectionType,
} from "../../connectionUtils";
import {
  TargetFragment,
  TargetFragment_filter,
  TargetFragment_limit,
} from "../__generated__/TargetFragment";
import { targetFilterTypeLabels } from "../TargetForm/TargetFilter";
import { outcomeIdFromLimit } from "../TargetForm/TargetLimit";
import { TargetMode } from "../TargetForm/TargetRepresentationRadios";
import {
  DEFAULT_PRESET_STRING,
  presetFor,
  ruleForPreset,
  TransformPresetType,
} from "../TargetForm/targetStructureUtils";
import { ScopeDependencies } from "../useScopeDependencies";
import { findConnectionNameByTarget } from "./TargetCardBase";

function TargetConfigItem({
  label,
  text,
}: {
  label?: string;
  text: ReactNode;
}) {
  return (
    <>
      <Flex
        sx={{
          gap: 0.5,
        }}
      >
        <Text
          as="dt"
          sx={{
            fontWeight: "bold",
          }}
        >
          {label ? `${label}:` : ""}
        </Text>
        <Text as="dd">{text}</Text>
      </Flex>
    </>
  );
}

function TargetConfigList({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="dl"
      sx={{
        mb: 2,
      }}
    >
      {children}
    </Box>
  );
}

/**
 * Render the `target.representation` and values with human readable labels.
 */
function TargetRepresentationInfo({
  representation,
  scopeDeps,
}: {
  representation: TargetFragment["representation"];
  scopeDeps: ScopeDependencies;
}) {
  // TODO: map other representation options to human readable labels if necessary

  return (
    <>
      <TargetConfigItem label={"Mode"} text={titleCase(representation.mode)} />
      {representation.__typename === "TargetModesAggregated" && (
        <>
          <TargetConfigItem
            label={"Aggregated by"}
            text={titleCase(
              representation.geographicAggregate.replace(/_/g, " ")
            )}
          />
          <TargetConfigItem
            label={"Include geometry"}
            text={titleCase(String(representation.includeGeometry))}
          />
        </>
      )}
      {representation.__typename === "TargetModesIdentified" && (
        <TargetConfigItem
          label={"Aggregated by"}
          text={titleCase(representation.identifiedAggregate)}
        />
      )}
      {representation.__typename === "TargetModesReferenced" &&
        representation.reference && (
          <>
            <TargetConfigItem
              label={"Reference Dataset"}
              text={
                <RouterLink
                  routeName={ROUTE_NAMES.DATASETS_DEFINITION}
                  params={{ id: representation.reference.datasetId }}
                >
                  {
                    scopeDeps.scopeDatasets.find(
                      (d) => d.id === representation.reference.datasetId
                    )?.name
                  }
                </RouterLink>
              }
            />
            <TargetConfigItem
              label={"Reference Column"}
              text={representation.reference.columnName}
            />
          </>
        )}
    </>
  );
}

/**
 * Render the `target.options` and values with human readable labels.
 */
function TargetOptionsInfo({
  options,
}: {
  options: TargetFragment["options"];
}) {
  // omit values that aren't user configured
  const { __typename, type, ...rest } = options;

  // create a map of options to the option literate for easy lookup
  // when we render the options and their values
  const optionLiteratesMap = useMemo(() => {
    const opts = getTargetOptionsByConnectionType(type);
    return opts.reduce((acc, opt) => {
      // convert slug back to camel case since we need to match it with the
      // incoming target options which are camel case
      const camelCaseSlug = camelCase(opt.slug);
      acc[camelCaseSlug] = opt.literate;
      return acc;
    }, {} as Record<string, string>);
  }, [type]);

  return (
    <>
      {Object.entries(rest).map(([key, value]) => {
        const literate = optionLiteratesMap[key];

        if (!literate) return;

        // cast to string so we can see booleans
        const description =
          value !== null && value !== undefined ? String(value) : <em>None</em>;

        return (
          <TargetConfigItem key={key} label={literate} text={description} />
        );
      })}
    </>
  );
}

/**
 * Convert target limit to a human readable string.
 * @example {threshold:0.5, direction: "descending"} -> "Only the bottom 50%"
 */
function labelForLimit(limit: TargetFragment_limit): string {
  if (limit.__typename === "TargetLimitRowCount") {
    const count = limit.threshold.toLocaleString();
    const direction =
      limit.direction === Direction.ASCENDING ? "bottom" : "top";

    if (!limit.rowCountOutcomeId) {
      return count;
    }

    return `Only the ${direction} ${count}`;
  } else if (limit.__typename === "TargetLimitPercentile") {
    return `Only the score percentiles between ${limit.percentileMin.toLocaleString()} and ${limit.percentileMax.toLocaleString()}`;
  }
  throw new UnreachableCodeError(limit);
}

/**
 * Renders the target.limit in a human readable format.
 */
function TargetLimitInfo({
  limit,
  outcomes,
}: {
  limit: TargetFragment_limit | null;
  outcomes: ScopeDependencies["payloadOutcomes"];
}) {
  if (!limit) return null;

  const outcome = outcomes.find((o) => o.id === outcomeIdFromLimit(limit));

  const limitLabel = labelForLimit(limit);

  const value = outcome ? `${outcome?.name} - ${limitLabel}` : limitLabel;

  return <TargetConfigItem text={value} />;
}

/**
 * Convert target filter to a human readable string.
 */
function targetFilterToInfo({
  filter,
  scopeDeps,
  traitsMap,
}: {
  filter: TargetFragment_filter;
  scopeDeps: ScopeDependencies;
  traitsMap: TraitsMap;
}) {
  const targetResults: {
    group: string;
    conditions: string[];
  }[] = [];

  const { payloadOutcomes, payloadPersonaSets, payloadCohorts } = scopeDeps;

  const outcomesById = keyBy(payloadOutcomes, (o) => o.id);
  const recommendersById = keyBy(scopeDeps.payloadRecommenders, (r) => r.id);

  if (filter.outcomePercentile.length) {
    const outcomePercentileConditions = filter.outcomePercentile.map((cond) => {
      const outcome = outcomesById[cond.outcomeId];
      const conds = conditionToSummary(cond);
      return `${outcome?.name}: ${conds.join(", ")}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.outcomePercentile,
      conditions: outcomePercentileConditions,
    });
  }

  if (filter.outcomeScore.length) {
    const outcomeScoreConditions = filter.outcomeScore.map((cond) => {
      const outcome = outcomesById[cond.outcomeId];
      const conds = conditionToSummary(cond);
      return `${outcome?.name}: ${conds.join(", ")}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.outcomeScore,
      conditions: outcomeScoreConditions,
    });
  }

  if (filter.outcomeProbability.length) {
    const outcomeProbabilityConditions = filter.outcomeProbability.map(
      (cond) => {
        const outcome = outcomesById[cond.outcomeId];
        const conds = conditionToSummary(cond);
        return `${outcome?.name}: ${conds.join(", ")}`;
      }
    );
    targetResults.push({
      group: targetFilterTypeLabels.outcomeProbability,
      conditions: outcomeProbabilityConditions,
    });
  }

  if (filter.persona.length) {
    const personaSetsById = keyBy(payloadPersonaSets, (ps) => ps.id);
    const personasById = keyBy(
      payloadPersonaSets.flatMap((ps) => ps.personas),
      (p) => p.id
    );
    const personaConditions = filter.persona.map((p) => {
      const personaSet = personaSetsById[p.personaSetId];
      const persona = personasById[p.personaId];
      return `${personaSet?.name} - ${persona?.name}: Equal to ${p.eq}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.persona,
      conditions: personaConditions,
    });
  }

  if (filter.cohortMembership.length) {
    const cohortsById = keyBy(payloadCohorts, (c) => c.id);
    const cohortMembershipConditions = filter.cohortMembership.map((cond) => {
      const cohort = cohortsById[cond.cohortId];
      return `${cohort?.name}: Equal to ${cond.eq}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.cohortMembership,
      conditions: cohortMembershipConditions,
    });
  }

  if (filter.trait.length) {
    const traitConditions = filter.trait.map((cond) => {
      const trait = traitsMap[cond.name];
      const conds = trait
        ? traitConditionToSummary(trait, cond)
        : conditionToSummary(cond);
      return `${trait?.name} - ${conds.join(", ")}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.trait,
      conditions: traitConditions,
    });
  }

  if (filter.recommenderRank.length) {
    const conditions = filter.recommenderRank.map((cond) => {
      const recommender = recommendersById[cond.recommenderId];
      return `${recommender.name}: ${conditionToSummary(cond).join(", ")}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.recommenderRank,
      conditions,
    });
  }

  if (filter.recommenderUncalibratedProbability.length) {
    const conditions = filter.recommenderUncalibratedProbability.map((cond) => {
      const recommender = recommendersById[cond.recommenderId];
      return `${recommender.name}: ${conditionToSummary(cond).join(", ")}`;
    });
    targetResults.push({
      group: targetFilterTypeLabels.recommenderUncalibratedProbability,
      conditions,
    });
  }

  return targetResults;
}

/**
 * Renders the target.filter in a human readable format.
 */
function TargetFilterInfo({
  filter,
  scopeDeps,
  traitsMap,
}: {
  filter: TargetFragment_filter | null;
  scopeDeps: ScopeDependencies;
  traitsMap: TraitsMap;
}) {
  const info = useMemo(() => {
    if (!filter) return null;
    return targetFilterToInfo({
      filter,
      scopeDeps,
      traitsMap,
    });
  }, [filter, scopeDeps, traitsMap]);

  if (!info) return null;

  const targetFilterText = (
    <ul>
      {info.map((filter) => (
        <li key={filter.group}>
          <strong>{filter.group}</strong>
          <ul
            style={{
              paddingLeft: "1rem",
            }}
          >
            {filter.conditions.map((condition, i) => (
              <li key={i}>{condition}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );

  return <TargetConfigItem text={targetFilterText} />;
}

function TargetStructureInfo({
  mode,
  preset,
  humanReadable,
  customStructure,
}: {
  mode: TargetMode;
  preset: TransformPresetType | null;
  humanReadable: boolean;
  customStructure: TargetStructureTransformationInput[] | null;
}) {
  let text: ReactNode;
  if (customStructure) {
    text = (
      <Text>
        Custom -{" "}
        {customStructure.length === 0
          ? "(no payload)"
          : customStructure.map((s) => s.outputCol).join(", ")}
      </Text>
    );
  } else {
    text = (
      <Text>
        {ruleForPreset(mode, preset ?? DEFAULT_PRESET_STRING).literate}{" "}
        {preset === DEFAULT_PRESET_STRING && humanReadable
          ? " (human-readable)"
          : ""}
      </Text>
    );
  }
  return <TargetConfigItem text={text} />;
}

/**
 * Render a connections logo and literate
 */
function TargetConnectionRowValue({
  target,
  connections,
}: {
  target: TargetFragment;
  connections: Connection[];
}) {
  const connectionName = findConnectionNameByTarget(connections, target);
  const info = getConnectionTypeInfoFromTargetOptions(target.options);

  const label =
    connectionName === "lookup_api" ? info.literate : connectionName;

  return (
    <Flex sx={{ gap: 2 }}>
      <VendorLogo path={info.logo_url} inline={true} dimension={24} />
      {target.connectionId ? (
        <ResourceLink
          resource={{
            __typename: "Connection",
            id: target.connectionId,
            name: label,
          }}
        >
          {label}
        </ResourceLink>
      ) : (
        label
      )}
    </Flex>
  );
}

/**
 * Render a list of the target options and advanced config such as filter and limit, where each has a human readable label.
 */
export function TargetListItemConfig({
  target,
  scopeDeps,
  traitsMap,
  connections,
}: {
  target: TargetFragment;
  scopeDeps: ScopeDependencies;
  traitsMap: TraitsMap;
  connections: Connection[];
}) {
  return (
    <TargetConfigList>
      <ConfigBreakdownTable>
        <ConfigBreakdownTableRow
          header="Connection"
          value={
            <TargetConnectionRowValue
              target={target}
              connections={connections}
            />
          }
        />
        <ConfigBreakdownTableRow
          header="Representation"
          value={
            <TargetRepresentationInfo
              representation={target.representation}
              scopeDeps={scopeDeps}
            />
          }
        />
        <ConfigBreakdownTableRow
          header="Filter"
          value={
            <TargetFilterInfo
              filter={target.filter}
              scopeDeps={scopeDeps}
              traitsMap={traitsMap}
            />
          }
        />
        <ConfigBreakdownTableRow
          header="Limit"
          value={
            <TargetLimitInfo
              limit={target.limit}
              outcomes={scopeDeps.payloadOutcomes}
            />
          }
        />
        <ConfigBreakdownTableRow
          header="Column Structure"
          value={
            <TargetStructureInfo
              mode={
                TargetMode[
                  target.representation.mode as keyof typeof TargetMode
                ]
              }
              preset={presetFor(target.representation)}
              humanReadable={target.humanReadable ?? false}
              customStructure={target.customStructure}
            />
          }
        />
        <ConfigBreakdownTableRow
          header="Options"
          value={<TargetOptionsInfo options={target.options} />}
        />
      </ConfigBreakdownTable>
    </TargetConfigList>
  );
}
