import { AllTraitsQuery } from "../../hooks/__generated__/AllTraitsQuery";
import { useToast } from "../../hooks/useToast";
import { TRAITS_QUERY, traitsToMap } from "../../hooks/useTraitsQuery";
import { useSojournerApolloClient } from "../../services/sojournerApolloClient";
import { downloadFile } from "../../utils/downloadFile";
import { PersonaSetFragment } from "./__generated__/PersonaSetFragment";
import {
  PersonaSetAnalysisHookQuery,
  PersonaSetAnalysisHookQueryVariables,
} from "./analysis/__generated__/PersonaSetAnalysisHookQuery";
import { PERSONA_SET_ANALYSIS_HOOK } from "./analysis/usePersonaSetAnalysis";
import { makeTraitDimensionRows } from "./analysis/utils";
import { personaSetAnalysisToCSV } from "./personaSetAnalysisToCSV";

export function usePersonaSetAnalysisDownloadCSV(
  personaSet: PersonaSetFragment
) {
  const client = useSojournerApolloClient();
  const toast = useToast();

  async function downloadPersonaAnalysisCsv() {
    const analysisQuery = await client.query<
      PersonaSetAnalysisHookQuery,
      PersonaSetAnalysisHookQueryVariables
    >({
      query: PERSONA_SET_ANALYSIS_HOOK,
      variables: { id: personaSet.id },
    });

    const traitsQuery = await client.query<AllTraitsQuery>({
      query: TRAITS_QUERY,
    });

    const dims = makeTraitDimensionRows(
      analysisQuery.data.dimensions,
      traitsToMap(traitsQuery.data.traits)
    );

    const csv = personaSetAnalysisToCSV(dims, personaSet);
    const timestamp = personaSet.lastUpdatedOutputAt ?? "";
    const filename = `${personaSet.name}_${personaSet.id}_${timestamp}.csv`;

    downloadFile({
      content: csv,
      filename: filename,
      type: "text/csv;charset=utf-8;",
    });

    return filename;
  }

  async function handleDownloadAnalysis() {
    const download = new Promise<string>((resolve, reject) => {
      downloadPersonaAnalysisCsv()
        .then((name) => {
          resolve(name);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });

    toast.promise(download, {
      loading: {
        title: "Preparing analysis...",
      },
      success: (name) => ({
        title: "Downloading...",
        description: (
          <>
            Your analysis is now downloading as <b>{name}</b>
          </>
        ),
      }),
      error: {
        title: "Error downloading analysis",
        description: "Please try again later",
      },
    });
  }

  return handleDownloadAnalysis;
}
