import { Link } from "@chakra-ui/react";
import { useId, useState } from "react";

import { DatasetPostInput } from "../../../__generated__/sojournerGlobalTypes";
import { uuid } from "../../../utils/uuid";
import { validateCSVHeadersMatch } from "../../../utils/validateCSVHeadersMatch";
import { Button } from "../../ui/Button";
import { FormField } from "../../ui/FormField";
import { FormFieldStack } from "../../ui/FormFieldStack";
import { InlineButtons } from "../../ui/InlineButtons";
import { ModalV2 } from "../../ui/ModalV2";
import { MultiFileDropZone } from "../../ui/MultiFileDropZone";
import {
  FileWithStatus,
  useUploadableFiles,
} from "../shared/useMultiFileUpload";
import {
  DatasetsCreateNameField,
  validateDatasetName,
} from "./DatasetsCreateNameField";
import { useCreateDataset } from "./useCreateDataset";

/**
 * Renders a form to create a new hosted csv dataset
 */
export function DatasetsCreateModalHostedCsv({
  isOpen,
  onBack,
  onClose,
}: {
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
}) {
  const csvFormId = useId();
  const [name, setName] = useState<string>("");
  // Generate a random ID for upload directory, otherwise if you create a separate
  // dataset and upload the same file, you can overwrite the first dataset.
  const uploadDirectory = uuid().replace(/-/g, "_");
  const { uploadFiles, files, setFiles, someFilesUploading } =
    useUploadableFiles(uploadDirectory);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { createDataset, loading: creatingDataset } = useCreateDataset();

  function handleFilesChange(files: FileWithStatus[]) {
    setFiles(files);

    if (files.length) {
      setErrors({ ...errors, file: "" });

      // set dataset name to file name if it wasn't set manually
      if (!name.trim()) {
        setName(files[0].file.name);
        setErrors({ ...errors, name: "" });
      }
    }
  }

  async function createCsvDataset() {
    try {
      await uploadFiles();
    } catch (e) {
      // TODO: it'd be great to shows errors that users can act on - like max file size, etc.
      setErrors({
        ...errors,
        file: "There was an error uploading your file. Please try again.",
      });
      return;
    }

    const dataset: DatasetPostInput = {
      preview: true,
      name,
      // TODO: remove this once PR for optional identity sets is in
      // we fill in id sets after POST, when we have detected columns
      identitySets: {},
      options: {
        type: "hosted_csv",
        uploadDirectory,
      },
    };

    createDataset(dataset);
  }

  async function handleSubmit() {
    const filesErr = await validateCSVHeadersMatch(files.map((f) => f.file));
    if (filesErr) {
      setErrors({ ...errors, file: filesErr });
      return;
    }

    const nameErr = validateDatasetName(name);
    if (nameErr) {
      setErrors({ ...errors, name: nameErr });
      return;
    }

    await createCsvDataset();
  }

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onClose}
      title="CSV Dataset"
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onBack} analyticsName="back">
            Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={csvFormId}
            loadingText="Creating dataset..."
            isLoading={creatingDataset || someFilesUploading}
            isDisabled={creatingDataset || someFilesUploading}
            analyticsName="finish"
          >
            Create dataset
          </Button>
        </InlineButtons>
      }
      analyticsStackName="hosted-csv-dataset-modal"
    >
      <form
        id={csvFormId}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormFieldStack>
          <FormField
            label="Upload CSV file(s)"
            error={errors.file}
            helpText={
              <>
                Add one or more CSV files to get started. Once this dataset is
                created, you can add more files later in the dataset's Data tab
                or{" "}
                <Link href="https://faraday.ai/developers/reference/createupload">
                  via the API
                </Link>
                .{" "}
                <strong>
                  Files uploaded will be the template for this dataset
                </strong>
                , and any future upload will need to match the format of it.
              </>
            }
          >
            <MultiFileDropZone
              files={files}
              onFilesChange={handleFilesChange}
              required
            />
          </FormField>

          <DatasetsCreateNameField
            name={name}
            setName={setName}
            error={errors.name}
            setError={(err) => {
              return { ...errors, name: err };
            }}
          />
        </FormFieldStack>
      </form>
    </ModalV2>
  );
}
