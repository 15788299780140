import { Text } from "@chakra-ui/react";
import {
  CheckCircle,
  CircleHalfTilt,
  IconProps,
  MagicWand,
  WarningCircle,
} from "@phosphor-icons/react";
import { ReactNode } from "react";
import { FormatOptionLabelMeta } from "react-select";

import { colors } from "../../../../../styles/chakra-theme-v2";
import { exists } from "../../../../../utils/exists";
import { percent } from "../../../../../utils/formatters";
import { IconWithText } from "../../../../ui/IconWithText";
import { DatasetSelectOption } from "../../../shared/reactSelectStyle";
import { DatasetColumn } from "./DatasetColumn";
import { DetectedColumn } from "./types";

export type SampleData = {
  [columnName: string]: string[];
};

// api.interfaces.yml only specifies that it's a SojJSON
// make a type that's more precise
export function isSampleData(
  sampleData: SojJSON | null
): sampleData is SampleData {
  return (
    !!sampleData &&
    Object.keys(sampleData).length > 0 &&
    Object.values(sampleData).every((sample) => Array.isArray(sample))
  );
}

export function hintFromSampleData({
  sampleData,
  column,
}: {
  sampleData?: SampleData;
  column?: DetectedColumn;
}) {
  const columnName = column?.name;
  if (!sampleData || !columnName || !sampleData[columnName]) return undefined;
  return sampleData[columnName].join(" • ");
}

const FillRateIcon = ({ fillRate }: { fillRate: number }) => {
  const baseProps: IconProps = {
    weight: "fill",
    size: 12,
  };

  if (fillRate >= 0.8) {
    return <CheckCircle color={colors.fdy_green[600]} {...baseProps} />;
  } else if (fillRate >= 0.3) {
    return <CircleHalfTilt color={colors.fdy_gray[500]} {...baseProps} />;
  } else {
    return <WarningCircle color={colors.fdy_red[300]} {...baseProps} />;
  }
};

const RecommendedLabel = () => (
  <Text
    sx={{
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: 12,
      color: "fdy_green.600",
      display: "flex",
      alignItems: "center",
      gap: 2,
      mb: 2,
    }}
  >
    <MagicWand weight="fill" />
    Recommended
  </Text>
);

function OptionWithSampleData({
  value,
  hint,
  fillRate,
  isRecommended,
}: DatasetSelectOption) {
  return (
    <>
      {/* The mocks ask for one 'recommended' label above all the options but as 
      we currently have it configured, only one column will be recommended at a time, so we can render it here. */}
      {isRecommended && <RecommendedLabel />}

      <DatasetColumn columnName={value} />

      {hint && (
        <Text color="fdy_gray.600" fontSize="fdy_sm">
          {hint}
        </Text>
      )}
      {exists(fillRate) && (
        <IconWithText>
          <FillRateIcon fillRate={fillRate} />
          <Text color="fdy_gray.600" fontSize="fdy_sm">
            <strong>{percent(fillRate)}</strong> fill rate
          </Text>
        </IconWithText>
      )}
    </>
  );
}

export function formatOptionLabel(
  data: DatasetSelectOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<DatasetSelectOption>
): ReactNode {
  // declutter the UI
  // show the sample data in the menu option, NOT in the select's display value
  if (formatOptionLabelMeta.context === "menu") {
    return <OptionWithSampleData {...data} />;
  }
  return <DatasetColumn columnName={data.value} />;
}
