import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useState } from "react";

import { useConnectionShowSuspenseQuery } from "../../../../hooks/api";
import { number } from "../../../../utils/formatters";
import { findConnectionTypeInfoBySlug } from "../../../pipelines/connectionUtils";
import { Blankslate } from "../../../ui/Blankslate";
import { CardV2 } from "../../../ui/Card/CardV2";
import { CardStack } from "../../../ui/CardStack";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../../ui/ConfigBreakdownTable";
import { IconWithText } from "../../../ui/IconWithText";
import { Pagination } from "../../../ui/Pagination-v2/Pagination";
import { ResourceLink } from "../../../ui/ResourceLink";
import { TimeAgo } from "../../../ui/TimeAgo";
import { VendorLogo } from "../../../ui/VendorLogo";
import { DatasetShowPageQuery_dataset } from "../../__generated__/DatasetShowPageQuery";
import { DatasetSampleCard } from "./DatasetSampleCard";

const PAGE_SIZE = 10;

function DatasetUpdatesCard({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  const updates = [...dataset.updates]; // clone array so we can sort/mutate it
  const [page, setPage] = useState<number>(1);

  if (updates.length === 0)
    return (
      <CardV2 title="Updates">
        <Blankslate
          title="No updates found"
          text="Please go to Connections to confirm your connection is working"
          filled
        />
      </CardV2>
    );

  return (
    <CardV2 title="Updates">
      <Table mb={4}>
        <Thead>
          <Tr>
            <Th>Upload date</Th>
            <Th>Rows</Th>
          </Tr>
        </Thead>
        <Tbody>
          {updates
            .sort((a, b) => {
              if (a.datetime < b.datetime) return 1;
              if (a.datetime > b.datetime) return -1;
              return 0;
            })
            .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
            .map((update, idx) => (
              <Tr key={idx}>
                <Td>
                  <TimeAgo date={update.datetime} />
                </Td>
                <Td>{number(update.rowsAdded)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Pagination
        total={updates.length}
        pageSize={PAGE_SIZE}
        current={page}
        onChange={(newPage) => {
          setPage(newPage);
        }}
      />
    </CardV2>
  );
}

function ConnectionPill({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  if (!dataset.connectionId) throw new Error("Connection ID is required");
  const { data: connection } = useConnectionShowSuspenseQuery(
    dataset.connectionId
  );

  const info = findConnectionTypeInfoBySlug(dataset.options.type);

  return (
    <ResourceLink resource={connection}>
      <IconWithText>
        <VendorLogo path={info.logo_url} />
        {connection.name}
      </IconWithText>
    </ResourceLink>
  );
}

function DatasetOptionsCard({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  const { options } = dataset;
  const { type, __typename, ...rest } = options;

  return (
    <CardV2 title="Connection details">
      <ConfigBreakdownTable>
        <ConfigBreakdownTableRow
          header="Connection"
          value={<ConnectionPill dataset={dataset} />}
        />
        {Object.entries(rest).map(([key, value]) => {
          if (value === null || value === undefined) return null;
          return (
            <ConfigBreakdownTableRow
              key={key}
              header={key}
              value={String(value)}
            />
          );
        })}
      </ConfigBreakdownTable>
    </CardV2>
  );
}

export function DatasetDataTabOther({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  return (
    <CardStack>
      <DatasetOptionsCard dataset={dataset} />
      <DatasetUpdatesCard dataset={dataset} />
      <DatasetSampleCard dataset={dataset} />
    </CardStack>
  );
}
