import { Stream } from "@fdy/faraday-js";

import { number } from "../../../utils/formatters";
import { CardV2 } from "../../ui/Card/CardV2";
import { PendingValue } from "../../ui/PendingValue";
import { SummaryGroup, SummaryItem } from "../../ui/SummaryCard";
import { PendingEventDate } from "./PendingEventDate";

export const EventStreamSummaryCard = ({ stream }: { stream: Stream }) => {
  return (
    <CardV2 title="Summary">
      <SummaryGroup>
        <SummaryItem title="First event">
          <PendingEventDate
            stream={stream}
            renderDate={(stream) => stream.oldest_date}
          />
        </SummaryItem>
        <SummaryItem title="Event count">
          <PendingValue value={stream.event_count} formatter={number} />
        </SummaryItem>
        <SummaryItem title="Most recent event">
          <PendingEventDate
            stream={stream}
            renderDate={(stream) => stream.newest_date}
          />
        </SummaryItem>
      </SummaryGroup>
    </CardV2>
  );
};
