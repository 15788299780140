import { Box, Text } from "@chakra-ui/react";
import { Download } from "@phosphor-icons/react";

import { Button } from "../../ui/Button";
import { PersonaSetFragment } from "../__generated__/PersonaSetFragment";
import { Persona } from "../personaSetFragment";
import { usePersonaSetAnalysisDownloadCSV } from "../usePersonaSetAnalysisDownloadCSV";
import { PersonaAvatar } from "./PersonaAvatar";
import { PersonaSetAnalysisGridRow } from "./PersonaSetAnalysisGrid";

function DownloadAnalysisButton({
  personaSet,
}: {
  personaSet: PersonaSetFragment;
}) {
  const download = usePersonaSetAnalysisDownloadCSV(personaSet);
  return (
    <Button
      size="sm"
      onClick={download}
      leftIcon={<Download weight="bold" />}
      variant="outline"
      analyticsName="download-persona-analysis-csv"
    >
      Download CSV
    </Button>
  );
}

/**
 * Render a row of personas, each with a name and avatar.
 */
export function PersonaSetAnalysisHeader({
  personas,
  personaSet,
}: {
  personas: Persona[];
  personaSet: PersonaSetFragment;
}) {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        width: "100%",
        bg: "white",
        zIndex: 10,
        borderBottom: "1px solid",
        borderColor: "fdy_gray.200",
      }}
    >
      <PersonaSetAnalysisGridRow columns={personas.length + 1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DownloadAnalysisButton personaSet={personaSet} />
        </Box>

        {personas.map((persona) => (
          <Box
            key={persona.id}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PersonaAvatar persona={persona} />
            <Box>
              <Text
                sx={{
                  fontWeight: "bold",
                  fontsize: "fdy_lg",
                  ml: 4,
                }}
              >
                {persona.name}
              </Text>
            </Box>
          </Box>
        ))}
      </PersonaSetAnalysisGridRow>
    </Box>
  );
}
