import { ResourceType } from "@fdy/faraday-js";
import { List, User } from "@phosphor-icons/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { number, percent } from "../../../utils/formatters";
import { findConnectionTypeInfoBySlug } from "../../pipelines/connectionUtils";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../ui/ConfigBreakdownTable";
import { IconWithText } from "../../ui/IconWithText";
import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import { VendorLogo } from "../../ui/VendorLogo";
import {
  DatasetsTableQuery_connections,
  DatasetsTableQuery_datasets as Dataset,
} from "../__generated__/DatasetsTableQuery";
import { DatasetsActionsMenu } from "../shared/DatasetsActionsMenu";
import { datasetTooltips } from "./datasetTooltips";

function DatasetCountsBreakdown({ dataset }: { dataset: Dataset }) {
  return (
    <ConfigBreakdownTable
      headers={["Element", "Count"]}
      caption={datasetTooltips.count}
    >
      <ConfigBreakdownTableRow
        icon={List}
        header="Rows"
        value={dataset.rowCount ? number(dataset.rowCount) : null}
      />
      <ConfigBreakdownTableRow
        icon={User}
        header="People"
        value={dataset.identifiedCount ? number(dataset.identifiedCount) : null}
      />
    </ConfigBreakdownTable>
  );
}

function DatasetCountPill({ dataset }: { dataset: Dataset }) {
  return (
    <PopoverInfoPill popover={<DatasetCountsBreakdown dataset={dataset} />}>
      <PopoverInfoPillPart
        icon={List}
        value={dataset.rowCount ? number(dataset.rowCount) : ""}
      />
    </PopoverInfoPill>
  );
}

function DatasetOutputBreakdown({ dataset }: { dataset: Dataset }) {
  return (
    <ConfigBreakdownTable
      headers={["Output type", "Outputs"]}
      caption={datasetTooltips.output}
    >
      <ConfigBreakdownTableRow
        icon={ResourceIcon.streams}
        header="Events"
        value={Object.keys(dataset.outputToStreams ?? {}).join(", ")}
      />
      <ConfigBreakdownTableRow
        icon={ResourceIcon.traits}
        header="Traits"
        value={Object.keys(dataset.outputToTraits ?? {}).join(", ")}
      />
    </ConfigBreakdownTable>
  );
}

function DatasetOutputPill({ dataset }: { dataset: Dataset }) {
  const traitCount = Object.keys(dataset.outputToTraits ?? {}).length;
  const eventCount = Object.keys(dataset.outputToStreams ?? {}).length;

  if (!traitCount && !eventCount) return null;

  return (
    <PopoverInfoPill popover={<DatasetOutputBreakdown dataset={dataset} />}>
      {eventCount > 0 && (
        <PopoverInfoPillPart icon={ResourceIcon.streams} value={eventCount} />
      )}
      {traitCount > 0 && (
        <PopoverInfoPillPart icon={ResourceIcon.traits} value={traitCount} />
      )}
    </PopoverInfoPill>
  );
}

const BLANK_SLATE_PROPS = {
  title: "No datasets found",
  text: "Datasets let you pull data into Faraday for use in predictions. You can upload CSVs or add data from a Connection.",
  button: {
    routeName: ROUTE_NAMES.CONNECTIONS,
    children:
      "If you are not uploading a CSV, use Connections to add an external data system.",
  },
};

export function DatasetsTable({
  datasets,
  connections,
  loading,
}: {
  datasets: Dataset[] | undefined;
  connections: DatasetsTableQuery_connections[] | undefined;
  loading: boolean;
}) {
  const tableColumns: ColumnDef<Dataset>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        id: "connection",
        header: "Connection",
        cell: ({ row }) => {
          if (!row.original.connectionId) return null;
          const conn = connections?.find(
            (c) => c.id === row.original.connectionId
          );
          if (!conn) return null;
          const connType = findConnectionTypeInfoBySlug(conn.options.type);

          return (
            <IconWithText>
              <VendorLogo path={connType.logo_url} dimension={20} />
              <ResourceLink resource={conn}>
                {conn.name === "hosted_csv" ? "CSV" : conn.name}
              </ResourceLink>
            </IconWithText>
          );
        },
      },
      {
        id: "count",
        header: "Count",
        meta: {
          headerTooltip: datasetTooltips.count,
        },
        cell: ({ row }) => {
          if (
            !row.original.lastUpdatedOutputAt ||
            row.original.mergeDatasets.length
          ) {
            return null;
          }

          return <DatasetCountPill dataset={row.original} />;
        },
      },
      {
        id: "output",
        header: "Output",
        meta: {
          headerTooltip: datasetTooltips.output,
        },
        cell: ({ row }) => <DatasetOutputPill dataset={row.original} />,
      },
      {
        id: "matchRate",
        header: "Match rate",
        meta: {
          headerTooltip: datasetTooltips.matchRate,
        },
        cell: ({ row }) => {
          // don't show 0% if there are no counts yet
          if (
            row.original.identifiedCount === null ||
            row.original.matchedCount === null
          ) {
            return null;
          }

          const matchRate =
            row.original.matchedCount / row.original.identifiedCount;

          return <span>{percent(matchRate)}</span>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
          <ResourceStatusBadge
            resource={row.original}
            titlePrefix={row.original.preview ? "Preview" : ""}
          />
        ),
        sortingFn: "basic",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.createdAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedConfigAt",
        header: "Modified",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedConfigAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedOutputAt",
        header: "Processed",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedOutputAt} />,
        sortingFn: "datetime",
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <DatasetsActionsMenu
            dataset={row.original}
            options={{
              view: true,
              edit: !row.original.managed,
              rename: !row.original.managed,
              api: true,
              delete: true,
              archive: true,
            }}
            buttonProps={{ variant: "icon", my: -2 }}
          />
        ),
      },
    ],
    [connections]
  );

  return (
    <ResourceTable<Dataset>
      resourceType={ResourceType.Datasets}
      blankslate={BLANK_SLATE_PROPS}
      loading={loading}
      data={datasets ?? []}
      columns={tableColumns}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
    />
  );
}
