const extractHeadersFromFile = async (file: File): Promise<string[] | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result as string;
      const lines = content.split("\n");
      const headers = lines[0]?.split(",").map((header) => header.trim());
      resolve(headers || null);
    };
    reader.onerror = () => resolve(null);
    reader.readAsText(file);
  });
};

export const validateCSVHeadersMatch = async (files: File[]) => {
  // store the headers of the first file as a reference
  let referenceHeaders: string[] | null = null;

  for (const file of files) {
    const headers = await extractHeadersFromFile(file);

    if (referenceHeaders === null) {
      referenceHeaders = headers;
    } else if (
      headers &&
      JSON.stringify(headers.sort()) !== JSON.stringify(referenceHeaders.sort())
    ) {
      return `File "${file.name}" has mismatched headers. All files should have the same headers.`;
    }
  }
};
